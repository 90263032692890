/* @media (-webkit-device-pixel-ratio: 1.25) {
:root {
zoom: 1;
}} */

.App {
  text-align: center;
  }
  
  .App-logo {
  height: 40vmin;
  pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
  .App-logo {
  animation: App-logo-spin infinite 20s linear;
  }
  }
  
  .scroll-tbl {
  max-height: 450px;
  overflow-y: scroll;
  }
  
  .btn-refresh {
  /* background: #6319b8 !important; */
  float: right;
  width: 140px; 
  }
  
  .position-sticky {
  position: sticky;
  top: -2px;
  }
  
  .scroll-tbl-batch .table-td td {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  }
  
  .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  }
  
  .App-link {
  color: #61dafb;
  }
  
  @keyframes App-logo-spin {
  from {
  transform: rotate(0deg);
  }
  
  to {
  transform: rotate(360deg);
  }
  }
  
  body {
  /* font-family: "Noto Sans JP", sans-serif; */
  font-size: 14px;
  font-weight: normal;
  min-height: 100vh;
  color: #4d4d4d !important;
  margin: 0;
  
  }
  
  html,
  body {
  height: 100%;
  }
  
  
  h1 {
  font-size: 35px;
  font-weight: bold;
  }
  
  h3 {
  font-size: 25px;
  font-weight: 700;
  }
  
  p {
  font-size: 14px;
  font-weight: normal;
  color: #333;
  }
  
  .form-control:focus {
  border-color: #ced4da !important;
  box-shadow: none !important;
  background-color: #ffff !important;
  }
  
  .btn-check:focus+.btn,
  .btn:focus {
  box-shadow: none !important;
  }
  

  
  .img-logo {
  width: 300px !important;
  }
  
  .common-btn {
  /* background: #6319b8 !important; */
  font-size: 14px !important;
  height: 45px;
  color: #fff !important;
  width: 100%;
  }
  
  .common-btn:hover {
    opacity: 0.8;
  /* background:#0e0e0f2d !important; */
  color: #fff;
  }

  .common-btn {
    /* background: #6319b8 !important; */
    font-size: 14px !important;
    height: 45px;
    color: #fff !important;
    width: 100%;
    }
    
    .login-btnProd {
      background: #6319b8 !important;
      font-size: 14px !important;
      height: 45px;
      color: #fff !important;
      width: 100%;
      }

      
      .login-btnProd:hover {
        opacity: 0.6;
      background:#481296 !important;
      color: #fff;
      }

      .login-btnDev {
        background: #d6c11e !important;
        font-size: 14px !important;
        height: 45px;
        color: #fff !important;
        width: 100%;
        }
  
        
        .login-btnDev:hover {
         
          opacity: 0.6;
        background:#b3ad09 !important;
        color: #fff;
        }

        .login-btnStg {
          background: #326c72 !important;
          font-size: 14px !important;
          height: 45px;
          color: #fff !important;
          width: 100%;
          }
    
          
          .login-btnStg:hover {
            opacity: 1;
          background:#44787d !important;
          color: #fff;
          }
  
  .textarea-candidates {
  border: 1px solid #d1d3e2;
  border-radius: .35rem;
  display: block;
  padding: 15px !important;
  font-weight: 400;
  color: #6e707e !important;
  height: auto !important;
  width: 780px !important;
  overflow-x: hidden;
  overflow-y: auto !important;
  }
  
  .pos-text {
  font-weight: 500 !important;
  font-size: 15px !important;
  color: #6e707e !important;
  }
  
  .textarea-candidates-text {
  color: #6e707e !important;
  font-size: 13px !important;
  list-style: none;
  }
  
  .copy-btn {
  color: #999 !important;
  }
  
  .copy-btn:hover {

  color: #0e0e0f2d !important;
  }
  
  
  
  .form-cnt {
  background-color: #e8e8f7 !important;
  height: 45px !important;
  border-radius: 5px !important;
  border: none !important;
  font-size: 14px !important;
  }
  
  .status-div {
  cursor: pointer;
  }
  
  .form-search {
  background-color: #ffffff !important;
  height: 45px !important;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: none;
  font-size: 14px;
  }
  
  .icon-search {
  /* background: #6319b8 !important; */
  color: #fff !important;
  padding: 0 30px !important;
  cursor: pointer;
  /* border: 1px #6319b8 !important; */
  }
  
  .active-circle {
  color: #6bfc35;
  }
  
  .disable-circle {
  color: #f7453c;
  }
  
  .drop-m {
  background-color: transparent !important;
  border: none !important;
  }
  
  .modal-content {
  border-radius: 10px !important;
  }
  
  .drop-btn {
  width: 100%;
  border: none;
  background: none;
  }
  
  .download-btn {
  color: #140f4b !important;
  border: 1px solid #140f4b !important;
  width: 250px;
  height: 45px;
  font-weight: bold !important;
  border-radius: 0 !important;
  }
  
  .download-btn:hover {

  color: #fff !important;
  background-color: #0e0e0f2d;
  }
  
  /********Login section*******/
  
  .backg-p {
  display: flex;
  align-items: center;
  justify-content: center;
  }
  
  .flex-container {
  height: 90vh;
  padding: 6px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  }
  
  .col-md6 {
  flex: 0 0 auto !important;
  width: 74% !important;
  }
  
  .sidebar-dark .nav-item .nav-link:active,
  .sidebar-dark .nav-item .nav-link:focus,
  .sidebar-dark .nav-item .nav-link:hover {
  color: #fff;

  background: #0e0e0f2d;
  border-radius: 0;
  }
  
  .activeProd {
  color: #fff;
  background: #481296;
  border-radius: 0;
  }
  .activeDev {
    color: #fff;
    background: #b3ad09;
    border-radius: 0;
    }
    .activeStg {
      color: #fff;
      background: #023e3f85;
      border-radius: 0;
      }
  
  /*******sidemenu******/
  .float-end-cstm {
  float: right !important;
  }
  
  .white-bg {
  background-color: #fff;
  }
  
  .logo-1 {
  color: #140f4b;
  font-size: 21px;
  font-weight: bold;
  }
  
  .logo {
  /* background: #6319b8; */
  min-height: 107.66px;
  }
  
  .logo img {
  width: 216px;
  }
  
  .sub-icon {
  color: #fff !important;
  font-size: 18px !important;
  float: left;
  width: 25px;
  }
  
  .side-postion {
  z-index: 99;
  }
  
  .sidebar .nav-item .nav-link span {
  display: inline-block;
  }
  
  .wrapper {
  min-height: 100vh !important;
  }
  
  .flex-fill {
  flex: 1 1 auto;
  }
  
  #wrapper #content-wrapper {
  background: #fff !important;
  height: 100vh;
  }
  
  .hide-logo {
  display: none;
  }
  
  .sidebar {
  width: 19rem !important;
  }
  
  .sidebar .nav-item .nav-link span {
  font-weight: bold;
  font-size: 14px;
  color: #f2f2f2;
  }
  
  .sidebar .nav-item .nav-link:hover span {
  font-weight: bold;
  color: #fff;
  }
  
  .nav-link {
  font-weight: bold;
  font-size: 14px;
  color: #f2f2f2;
  }
  
  .nav-link:hover {
  color: #fff !important;
  font-weight: bold;
  }
  
  .sidebar .nav-item .nav-link {
  padding: 0.5rem 1rem;
  }
  
  .nav-link .active {
  color: #fff !important;
  font-weight: bold;
  }
  
  .sidebar .nav-item .nav-link {
  width: 17rem !important;
  }
  
  .sidebar.toggled .nav-item .nav-link span {
  font-size: 14px !important;
  }
  
  .topbar .navbar-search {
  width: 100% !important;
  }
  
  #overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
  cursor: pointer;
  }
  
  .sidebar-divider {
  border-top: 1px solid #999 !important;
  margin-top: 30px;
  margin-bottom: 15px;
  }
  
  .imgdivsiz:hover .imgspn {
  opacity: 1;
  }
  
  .lft_menu_ht {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  }
  
  .lftpostion {
  position: relative;
  /* background: #6319b8 !important; */
  }
  
  .side-menus-pro {
  padding-left: 17px;
  padding-top: 15px;
  }
  
  .pro-name {
  border-bottom: 1px solid #fff;
  color: #fff;
  font-weight: bold;
  }
  
  .pro-name-2 {
  border-bottom: 1px solid #000;
  color: #000;
  font-weight: bold;
  }
  
  .dp-togle::after {
  color: #fff;
  }
  
  .dp-itm {
  color: #140f4b !important;
  font-weight: bold !important;
  padding: 8px 24px !important;
  }
  
  .dp-itm:active {
  background-color: #f7f7f7 !important;
  }
  
  /***header**/
  .topbar .dropdown .dropdown-menu {
  width: auto;
  right: 0;
  left: -65px;
  }
  
  /*footer*/
  footer {
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  }
  
  .text-p {
  color: #6b6a6b;
  font-size: 14px;
  }
  
  /*end*/
  
  /******middile section***/
  @media (min-width: 576px) {
  .modal-sm {
  max-width: 400px !important;
  }
  }
  
  .cancel-btn {
  background: #c1272d !important;
  font-size: 14px;
  height: 45px;
  color: #fff !important;
  width: 100%;
  }
  
  .done-btn {
  background: #278d3b !important;
  font-size: 14px;
  height: 45px;
  color: #fff !important;
  width: 100%;
  }
  
  .midle-heading {
  /* color: #6319b8; */
  font-size: 22px;
  font-weight: bolder;
  }
  
  .mdle-p {
  font-size: 17px;
  color: #000;
  }
  
  .create-btn {
  color: #140f4b !important;
  border: 1px solid #140f4b !important;
  width: 200px;
  height: 45px;
  font-weight: bold !important;
  border-radius: 0 !important;
  }
  
  .create-btn:hover {
  color: #fff !important;
 
  background-color: #0e0e0f2d;
  }
  
  .bg-table {
  /* background-color: #6319b8; */
  color: #fff;
  height: 60px;
  }
  
  .table thead th {
  vertical-align: middle !important;
  }
  
  .table-td td {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
  vertical-align: middle !important;
  color: #706f6fa6 !important;
  }
  
  .delete-btn {
  color: #c1272d !important;
  font-size: 18px !important;
  cursor: pointer;
  }
  
  .edit-btn {
  /* color: #6319b8 !important; */
  font-size: 18px !important;
  cursor: pointer;
  }
  
  .edit-btn-disabled {
  font-size: 18px !important;
  }
  
  .page-btn {
  background-color: transparent !important;
  border: none !important;
  color: #706f6f !important;
  }
  
  .pagination-btn .active {
  color: #140f4b !important;
  }
  
  .page-btn:focus {
  box-shadow: none !important;
  }
  
  .upload-btn {
  background-color: #e8e8f7 !important;
  border: 1px solid #140f4b !important;
  color: #140f4b !important;
  border-radius: 5px !important;
  width: 170px;
  height: 45px;
  }
  
  .radio-chck:checked {
  border-color: #140f4b !important;
  background-color: transparent !important;
  }
  
  .radio-chck {
  /* background-image: url(../images/radio-circle.png); */
  width: 20px;
  height: 20px;
  border: 1px solid #999;
  }
  
  .radio-chck:focus {
  box-shadow: none !important;
  }
  
  .formt-checkbx {
  width: 20px;
  height: 20px;
  border: 1px solid #999;
  margin-top: 2px !important;
  }
  
  .formt-checkbx:checked {
  background: #140f4b;
  border-color: #140f4b;
  }
  
  .formt-checkbx:focus {
  box-shadow: none !important;
  border-color: #999;
  }
  
  @media only screen and (max-width: 768px) {
  .sidebar {
  width: 14rem !important;
  min-height: 100vh;
  position: fixed;
  z-index: 500;
  }
  
  .sidebar .nav-item .nav-link {
  text-align: left;
  padding: 1rem 1rem !important;
  width: 12rem;
  }
  
  .sidebar.toggled {
  width: 12rem !important;
  overflow: hidden;
  }
  
  .sidebar.toggled .nav-item .nav-link {
  text-align: left !important;
  padding: 0.3rem 0rem;
  width: 12rem;
  }
  
  .sidebar.toggled .nav-item .nav-link span {
  font-size: 0.65rem;
  display: inline;
  }
  
  .bash-wdhd {
  width: 100%;
  height: 450px !important;
  }
  
  .map-wd img {
  height: 450px;
  width: 100%;
  }
  }
  
  @media only screen and (max-width: 991px) {
  .lftpostion {
  position: absolute;
  background: #140f4b;
  z-index: 3;
  }
  
  .hide-logo {
  display: block;
  }
  
  .pro-name-div {
  display: block;
  }
  
  .pro-name-2-div {
  display: none;
  }
  
  .sidebar-divider {
  display: block;
  }
  
  .table-td td {
  display: block;
  width: 100% !important;
  border-top: none !important;
  }
  
  
  .table-td th {
  display: block;
  width: 100% !important;
  border-top: none !important;
  color: #140f4b;
  }
  
  .table> :not(caption)>*>* {
  border-bottom-width: 0px !important;
  }
  
  .bg-table {
  display: none;
  }
  
  .table> :not(:first-child) {
  border-top: none !important;
  }
  
  .table-td tr {
  border: 1px solid #ccc;
  background: #fff;
  }
  
  .table-td td {
  padding-bottom: 5px !important;
  padding-top: 5px !important;
  }
  }
  
  @media only screen and (min-width: 991px) {
  .dmdblock {
  display: block !important;
  }
  
  .pro-name-div {
  display: none;
  }
  
  .pro-name-2-div {
  display: block;
  }
  
  .sidebar-divider {
  display: none;
  }
  
  .hide-th {
  display: none !important;
  }
  }
  
  @media (max-width: 576px) {
  .topbar .dropdown .dropdown-menu {
  width: auto;
  right: 0;
  left: 0;
  }
  }
  
  @media (max-width: 640px) {
  .col3-w {
  flex: 0 0 90% !important;
  max-width: 100% !important;
  }
  
  .hide-span {
  display: none;
  }
  }
  
  .sort:hover {
    opacity: 1;
  color: #fff !important;
  background-color: #0e0e0f2d;
  cursor: pointer;
  }
  
  /****user-details****/
  .avatr-bg {
  background-color: #cccccc96;
  width: 15rem !important;
  border-radius: 5px;
  padding: 10px;
  }
  
  .avatr-bg img {
  width: 180px;
  }
  
  .btn-edit {
  /* background: #6319b8 !important; */
  color: #fff !important;
  height: 40px;
  width: 200px;
  }
  
  .btn-edit:hover {
    opacity: 0.7;
  background: #111112 !important;
  }
  
  .form-select:focus {
  border-color: none;
  box-shadow: none;
  }
  
  .form-user {
  background-color: #e8e8f7 !important;
  height: 45px !important;
  border-radius: 5px;
  border: none;
  font-size: 14px !important;
  color: #858796;
  }
  
  .td-hdr {
  position: fixed;
  z-index: 999;
  }
  
  /******batch trigger***/
  .batch-icon {
  background: #cccccc96;
  }
  
  .batch-icon i {
  font-size: 6rem;
  /* color: #6319b8; */
  }
  
  .btn-trigger {
  /* background: #6319b8 !important; */
  color: #fff !important;
  height: 40px;
  }
  
  .btn-trigger:hover {
    opacity: 0.8;
  /* background: #131213 !important; */
  }
  
  .trigger-pop-border {
  border: 4px solid #f58942;
  width: 6rem !important;
  height: 6rem !important;
  border-radius: 50%;
  padding: 7px;
  }
  
  .trigger-pop {
  font-size: 3rem;
  color: #f58942;
  }
  
  .are-text {
  font-size: 16px;
  color: #000;
  font-weight: bold;
  }
  
  .td-hdr {
  position: sticky;
  top: -2px;
  }
  
  .table-fix {
  position: relative;
  top: -5px;
  }
  
  .hide-div {
  display: none;
  }
  
  .error-template {
  padding: 40px 15px;
  text-align: center;
  }
  
  .error-actions {
  margin-top: 15px;
  margin-bottom: 15px;
  }
  
  .loader,
  .loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
  }
  
  .loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid #e7e7e7;
  border-right: 1.1em solid #e7e7e7;
  border-bottom: 1.1em solid #e7e7e7;
  border-left: 1.1em solid #615f99;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  }
  
  @-webkit-keyframes load8 {
  0% {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  }
  
  100% {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  }
  }
  
  @keyframes load8 {
  0% {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  }
  
  100% {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  }
  }
  
  @media (min-width: 760px) and (-webkit-device-pixel-ratio: 1.25) {
    .cstm-height{
      height: 36px !important;
    }

  .left-manual {
  left: 31rem !important;
  }
  
  .full-width {
  width: 100% !important;
  }
  
  .cstm-fonts {
  font-size: 12px !important;
  }
  
  .cstm-title {
  /* width: 200px; */
  height: 64px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: auto !important;
  }
  
  .form-select {
  font-size: 12px !important;
  color: #6e707e !important;
  }
  
  .custom-dialog {
  top: 42px !important
  }
  
  .cstm-description {
  /* width: 200px; */
  height: 64px;
  width: auto !important;
  
  }
  
  .btn-download {
  /* background: #6319b8 !important; */
  color: #fff !important;
  height: 36px !important;
  width: auto !important;
  font-size: 12px !important;
  
  
  }
  
  .btn-downloadsub {
  /* background: #6319b8 !important; */
  color: #fff !important;
  height: 36px !important;
  
  width: auto !important;
  cursor: pointer;
  font-size: 12px !important;
  }
  
  .btn-downloadsub:hover {
    opacity: 0.7;
  background: #13121327 !important;
  color: #fff !important;
  height: 36px !important;
  
  left: 100px;
  width: auto !important;
  font-size: 12px !important;
  
  }
  
  .btn-downloads {
  
  /* background: #6319b8 !important; */
  color: #fff !important;
  height: 26px;
  /* padding-top: 4px; */
  border-radius: 50%;
  width: auto !important;
  font-size: 12px !important;
  /* margin-top: 10px !important; */
  box-shadow: 5px 5px 7px #d9d9d9, -7px -7px 15px #ffffff, inset 0 0 0 transparent, inset 0 0 0 transparent;
  /* transition: 0.5s ease-in-out; */
  cursor: pointer;
  
  }
  
  .btn-downloads:hover {
  transform: scale(1.06);
  color: white;
  border-color: #000000;
  
  }
  
  .btn-download:hover {
  
  background: #11111183 !important;
  transition-duration: 0.5s;
  transition-timing-function: ease-in;
  transition-delay: 0.3s;
  }
  
  .cstm-height-search {
  height: 40px !important;
  }
  
  .batch-text {
  font-size: 13px !important;
  }
  
  }
  
  @media only screen and (max-width: 768px) {
  .left-manual {
  width: 60px !important;
  height: 60px !important;
  }
  }
  
  .link-a {
  font-size: 14px;
  color: #6319b8;
  font-weight: bold;
  }
  
  .btn-add-close {
  width: 40px;
  }
  
  .upl-btn {
  width: 170px;
  }
  
  .toggle-btn {
  position: absolute;
  left: 17px;
  top: 17px;
  z-index: 9999;
  background: #fff !important;
  width: 30px;
  height: 30px;
  padding: 0px !important;
  margin-top: 7px;
  }
  
  @media only screen and (max-width: 768px) {
  .logo img {
  width: 200px;
  margin-top: 25px;
  }
  
  .custom-img {
  position: absolute !important;
  right: -22px;
  top: -15px;
  }
  
  .cstm-margin {
  margin-top: 4rem !important;
  }
  }
  
  .nav-cstm {
  position: fixed !important;
  width: 100%;
  z-index: 99;
  }
  
  
  .selected-words {
  width: auto !important;
  background: #481296;
  color: #fff;
  border-radius: 13px;
  padding-left: 10px !important;
  padding-right: 10px !important;
  height: 35px;
  padding-top: 5px;
  margin-left: 5px;
  margin-top: 3px !important;
  margin-bottom: 3px !important;
  }
  
  .jst-right {
  justify-content: right !important;
  }
  
  
  @media only screen and (max-width: 768px) {
  
  .custom-width {
  width: 132px !important;
  }
  
  .cstm-width {
  left: 23px !important
  }
  
  .cstm-float {
  float: right;
  }
  
  .cstm-col {
  flex: 0 0 50% !important;
  max-width: 50% !important;
  }
  }
  
  
  
  @media only screen and (max-width: 768px) {
    .bg-cstm {
      background-color: rgb(99, 25, 184) !important;
      
      }  
  
  .btn-import {
  /* background-color: rgb(99, 25, 184) !important; */
  width: 140px !important;
  height: 45px !important;
  }
  
  .btn-importcampaign {
  background-color: rgb(99, 25, 184) !important;
  width: 140px !important;
  height: 45px !important;
  }
  
  .btn-imports {
  /* background-color: rgb(99, 25, 184) !important; */
  width: 140px !important;
  height: 45px !important;
  }
  
  .btn-export {
  /* background-color: rgb(99, 25, 184) !important; */
  width: 120px !important;
  height: 45px !important;
  }
  
  .btn-generate {
  /* background-color: rgb(99, 25, 184) !important; */
  width: 300px !important;
  height: 45px !important;
  }
  
  .cstm-stack {
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  }
  
  .width-mob {
  width: 100% !important;
  }
  
  .margin-top-mob {
  margin-top: 10px !important;
  }
  
  .mob-hr {
  margin-bottom: 10px !important;
  }
  }
  
  @media only screen and (min-width: 768px) {

    .Suggestion-mainDiv{
      overflow-y: auto;
      max-height: 624px;
      /* top: 10%; */
      /* position: fixed !important; */
      /* right: 11%; */
      /* width: 20% !important; */
      background: white;
    }
    .Main-HintMngmntDiv{
      max-height: 810px;
      overflow-y: auto;
    }


  .bg-cstm {
  /* background-color: rgb(99, 25, 184) !important; */
  height: 39px !important;
  margin-right: 1.5rem !important;
  width: 250px;
  
  }
  
  .row-flex {
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  }
  
  .btn-download {
  /* background: #6319b8 !important; */
  color: #fff !important;
  height: 38px;
  
  width: auto !important;
  font-size: 16px;
  
  
  }
  
  .btn-downloadsub {
  background: #6319b8 !important;
  color: #fff !important;
  height: 38px;
  
  width: auto !important;
  cursor: pointer;
  font-size: 16px;
  }
  
  .btn-downloadsub:hover {
  background: #0e0e0f2d !important;
  color: #fff !important;
  height: 38px;
 
  left: 100px;
  width: auto !important;
  font-size: 16px;
  
  }
  
  .btn-downloads {
  
  /* background: #6319b8 !important; */
  color: #fff !important;
  height: 26px;
  padding-top: 4px;
  border-radius: 50%;
  width: auto !important;
  font-size: 13px;
  margin-top: 6px !important;
  box-shadow: 5px 5px 7px #d9d9d9, -7px -7px 15px #ffffff, inset 0 0 0 transparent, inset 0 0 0 transparent;
  /* transition: 0.5s ease-in-out; */
  cursor: pointer;
  
  }
  
  .btn-downloads:hover {
  transform: scale(1.06);
  color: white;
  border-color: #000000;
  
  }
  
  .btn-download:hover {
  
  background: #0e0e0f2d !important;
  transition-duration: 0.5s;
  transition-timing-function: ease-in;
  transition-delay: 0.3s;
  }
  
  .frame {
  
  width: 100%;
  height: 250px;
  position: relative;
  }
  
  .m-top-search {
  height: 36px !important;
  }
  
  .span-cstm1 {
  right: 0px !important;
  position: absolute;
  bottom: -2px;
  border: 0px !important;
  /* background-color: #6319b8 !important; */
  width: 30px;
  height: 30px !important;
  padding-left: 9px !important;
  padding-top: 5px !important;
  color: #fff !important;
  font-size: 14px !important;
  }

  .span-cstm3dot {
    z-index: 9;
    right: 0px !important;
    position: absolute;
    top: -2px;
    border: 0px !important;
    /* background-color: #6319b8 !important; */
    width: 29px;
    height: 28px !important;
    padding-left: 9px !important;
    padding-top: 4px !important;
    color: #fff !important;
    font-size: 14px !important;
}
    
  
  .span-cstm1Un {
  right: 0px !important;
  position: absolute;
  bottom: 1px;
  border: 0px !important;
  /* background-color: #6319b8 !important; */
  width: 30px;
  height: 30px !important;
  padding-left: 9px !important;
  padding-top: 5px !important;
  color: #fff !important;
  font-size: 14px !important;
  }
  
  .span-cstm1Dwnld {
  right: 0px !important;
  position: absolute;
  bottom: 1px;
  border: 0px !important;
  /* background-color: #6319b8 !important; */
  width: 30px;
  height: 30px !important;
  padding-left: 9px !important;
  padding-top: 5px !important;
  color: #fff !important;
  font-size: 14px !important;
  }
  
  .span-cstm1Sug {
  right: 0px !important;
  position: absolute;
  bottom: 1px;
  border: 0px !important;
  /* background-color: #6319b8 !important; */
  width: 30px;
  height: 30px !important;
  padding-left: 9px !important;
  padding-top: 5px !important;
  color: #fff !important;
  font-size: 14px !important;
  }
  
  .cstm-fonts {
  font-size: 14px !important;
  }
  
  .custom-dialog {
  top: 10px !important;
  }
  
  .marg-cstm {
  
  margin-right: 80rem !important;
  }
  
  .p-deposit {
  max-height: 35px;
  /* width: 10em; */
  overflow: hidden;
  /* make sure it hides the content that overflows */
  /* white-space: nowrap; don't break the line */
  /* text-overflow: ellipsis; give the beautiful '...' effect */
  font-size: 12px !important;
  }
  
  .cstm-description {
  /* width:320px; */
  width: 96% !important;
  
  height: 42px;
  }
  
  .logo-deposit {
  width: 300px !important;
  height: 250px !important;
  flex: 1 1 120px;
  }
  
  .cards {
  flex-wrap: wrap;
  }
  
  .cstm-title {
  /* width: 250px; */
  height: 52px;
  
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: auto !important;
  
  }
  
  .p-overlay {
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid black;
  padding-bottom: 0;
  margin-bottom: 0px !important;
  }
  
  .p-overlay-before {
  font-size: 16px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  border: 0;
  outline: 0;
  background: transparent;
  padding-bottom: 0;
  display: inline-block;
  margin-bottom: 0px !important;
  
  }
  
  .cstm-margin-gen {
  margin-right: 75px !important;
  }
  
  .padding-zero {
  padding-left: 0px !important;
  
  }
  
  .btn-importgoogle {
  width: 185px !important;
  height: 38px !important;
  /* background-color: rgb(99, 25, 184) !important; */
  
  }
  
  .btn-importapple {
  width: 185px !important;
  height: 38px !important;
  /* background-color: rgb(99, 25, 184) !important; */
  
  }
  
  .btn-import {
  /* background-color: rgb(99, 25, 184) !important; */
  width: 190px !important;
  height: 45px !important;
  }
  
  .btn-importcampaign {
  background-color: rgb(99, 25, 184) !important;
  width: 190px !important;
  height: 38px !important;
  }
  
  .btn-imports {
  /* background-color: rgb(99, 25, 184) !important; */
  width: 170px !important;
  height: 39px !important;
  }
  
  .btn-export {
  /* background-color: rgb(99, 25, 184) !important; */
  width: 185px !important;
  height: 38px !important;
  }
  
  .m-top {
  margin-top: 4px !important;
  }
  
  .btn-generate {
  /* background-color: rgb(99, 25, 184) !important; */
  width: 185px !important;
  height: 38px !important;
  }
  
  .cstm-stack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  }
  
  .mob-hr {
  display: none;
  }
  
  .margin-r-cstm {
  margin-right: 1.5rem !important;
  }
  }
  
  .cstm-link {
  cursor: pointer !important;
  color: blue !important;
  }

  
  
  .pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  
  outline: none;
  }
  
  .pagination>.active>a {
  background-color: rgb(99, 25, 184);
  border-color: rgb(99, 25, 184);
  border-radius: 50%;
  
  color: #fff;
  }
  
  .pagination>li>a {
  /* border: 1px solid #47ccde ; */
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
  }
  
  .pagination>.active>a,
  .pagination>.active>span,
  .pagination>.active>a:hover,
  .pagination>.active>span:hover,
  .pagination>.active>a:focus,
  .pagination>.active>span:focus {
  background-color: rgb(99, 25, 184);
  border-color: rgb(99, 25, 184);
  color: #FFF !important;
  outline: none;
  }
  
  .pagination>li>a,
  .pagination>li>span {
  color: #000000
  }
  
  .pagination>li:first-child>a,
  .pagination>li:first-child>span,
  .pagination>li:last-child>a,
  .pagination>li:last-child>span {
  border-radius: unset;
  
  }
  
  .activesProd {
  color: #fff;
  background: #481296;
  border-radius: 50% !important;
  }

  .activesDev {
    color: #fff;
    background: #d6c11e;
    border-radius: 50% !important;
    }
    .activesStg {
      color: #fff;
      background: #326c72;
      border-radius: 50% !important;
      }
  
  @media only screen and (max-width: 768px) {
  .static-top {
  width: 100% !important;
  position: fixed !important;
  z-index: 99 !important;
  }
  
  }
  
  @media (min-width: 768px) and (max-width:1279) {
  .static-top {
  width: 85% !important;
  position: fixed !important;
  z-index: 99 !important;
  }
  
  }
  
  
  #loader {
  left: 12rem;
  width: 80px;
  height: 80px;
  border: 12px solid #f3f3f3;
  border-radius: 50%;
  /* border-top: 12px solid #481296; */
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
  position: fixed;
  z-index: 999;
  overflow: show;
  margin: auto;
  top: 0rem !important;
  bottom: 0;
  right: 0;
  
  }
  
  #load {
  position: absolute;
  left: 50%;
  top: 10rem !important;
  z-index: 1;
  width: 80px;
  height: 80px;
  margin: -56px 0 0 -56px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  /* border-top: 16px solid #481296; */
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
  }
  
  @-webkit-keyframes spin {
  0% {
  -webkit-transform: rotate(0deg);
  }
  
  100% {
  -webkit-transform: rotate(360deg);
  }
  }
  
  @keyframes spin {
  0% {
  transform: rotate(0deg);
  }
  
  100% {
  transform: rotate(360deg);
  }
  }
  
  /* Add animation to "page content" */
  .animate-bottom {
  position: relative;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 1s;
  animation-name: animatebottom;
  animation-duration: 1s
  }
  
  @-webkit-keyframes animatebottom {
  from {
  bottom: -100px;
  opacity: 0
  }
  
  to {
  bottom: 0px;
  opacity: 1
  }
  }
  
  @keyframes animatebottom {
  from {
  bottom: -100px;
  opacity: 0
  }
  
  to {
  bottom: 0;
  opacity: 1
  }
  }
  
  #myDiv {
  display: none;
  text-align: center;
  }
  
  .loader-class {
  
  background: #fff;
  height: 100%;
  width: 100%;
  position: absolute;
  }
  
  .icon-clr i {
  font-size: 6rem;
  /* color: #6319b8; */
  }
  
  .check-card {
  position: absolute !important;
  top: -6px;
  left: -6px;
  }
  
  .form-inpt-check {
  z-index: 9999;
  width: 25px !important;
  height: 25px !important;
  }
  
  .rating {
  display: flex;
  /* flex-direction: row-reverse; */
  justify-content: left;
  margin-left: 15px;
  
  }
  
  .rating>input {
  display: none
  }
  
  .rating>label {
  position: relative;
  width: 1em;
  font-size: 25px;
  color: #FFD600;
  cursor: pointer
  }
  
  .rating>label::before {
  content: "\2605";
  position: absolute;
  opacity: 0
  }
  
  .rating>label:hover:before,
  .rating>label:hover~label:before {
  opacity: 1 !important
  }
  
  .rating>input:checked~label:before {
  opacity: 1
  }
  
  .rating:hover>input:checked~label:before {
  opacity: 0.4
  }
  
  .text-cstm-align {
  text-align: left !important;
  }
  
  .text-bold {
  font-weight: bolder;
  }
  
  .cstm-pad-zero {
  padding-top: 0px !important;
  }
  
  .modal-150vh {
  max-width: 160vh !important;
  }
  
  .border-cstm-btm {
  border-bottom: 1px rgb(192, 187, 187) solid;
  }
  
  .cstm-font-size {
  font-size: 16px !important;
  }
  
  .cstm-top {
  top: 150px !important
  }
  
  
  
  @media only screen and (min-width: 1280px) and (max-width:1400px) {
  .btn-export {
  width: 150px !important;
  font-size: 12px !important;
  height: 35px !important;
  }
  
  body {
  font-size: 12px !important;
  }
  
  .custom-width {
  font-size: 12px !important;
  height: 35px !important;
  }
  
  .btn-primary {
  height: 35px !important;
  
  }
  
  .marg-cstm {
  margin-right: 30rem !important;
  }
  
  .btn-importcampaign {
  background-color: rgb(99, 25, 184) !important;
  width: 150px !important;
  height: 38px !important;
  }
  
  .cstm-description {
  /* width: 233px; */
  height: 44px;
  width: auto I !important;
  }
  
  .p-overlay {
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid black;
  padding-bottom: 0;
  margin-bottom: 0px !important;
  }
  
  .cstm-title {
  /* width: 192px; */
  height: 52px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: auto !important;
  }
  
  .custom-dialog {
  top: 80px !important;
  }
  
  .logo-deposit {
  width: 185px !important;
  height: 180px !important;
  }
  
  .col-md-8-cstms {
  flex: 0 0 66.66667% !important;
  max-width: 66.66667% !important;
  }
  
  .col-md-3-cstm {
  flex: 0 0 25% !important;
  max-width: 25% !important;
  }
  
  .cstm-paad {
  padding: 0px !important;
  }
  
  .cstm-margin-rightt {
  margin-right: 90px !important;
  }
  
  .btn-importgoogle {
  width: 150px !important;
  height: 38px !important;
  /* background-color: rgb(99, 25, 184) !important; */
  
  }
  
  .zero-pad {
  padding-right: 0px !important;
  }
  
  .custom-dialog {
  top: 40px !important;
  }
  
  .btn-importapple {
  width: 150px !important;
  height: 38px !important;
  /* background-color: rgb(99, 25, 184) !important; */
  
  }
  
  .col-md-5-cstm {
  flex: 0 0 41.66667% !important;
  max-width: 41.66667% !important;
  }
  
  #loader {
  left: 12rem;
  width: 80px;
  height: 80px;
  border: 12px solid #f3f3f3;
  border-radius: 50%;
  /* border-top: 12px solid #481296; */
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
  position: fixed;
  z-index: 999;
  overflow: show;
  margin: auto;
  top: 0rem !important;
  bottom: 0;
  right: 0;
  }
  
  @-webkit-keyframes spin {
  0% {
  -webkit-transform: rotate(0deg);
  }
  
  100% {
  -webkit-transform: rotate(360deg);
  }
  }
  
  @keyframes spin {
  0% {
  transform: rotate(0deg);
  }
  
  100% {
  transform: rotate(360deg);
  }
  }
  
  /* Add animation to "page content" */
  .animate-bottom {
  position: relative;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 1s;
  animation-name: animatebottom;
  animation-duration: 1s
  }
  
  @-webkit-keyframes animatebottom {
  from {
  bottom: -100px;
  opacity: 0
  }
  
  to {
  bottom: 0px;
  opacity: 1
  }
  }
  
  @keyframes animatebottom {
  from {
  bottom: -100px;
  opacity: 0
  }
  
  to {
  bottom: 0;
  opacity: 1
  }
  }
  
  #myDiv {
  display: none;
  text-align: center;
  }
  
  .loader-class {
  
  background: #fff;
  height: 100%;
  width: 100%;
  position: absolute;
  }
  
  .sidebar .nav-item .nav-link span {
  font-size: 12px !important;
  }
  
  .logo img {
  width: 200px;
  }
  
  h5 {
  font-size: 20px !important;
  }
  
  .form-search {
  height: 36px !important;
  font-size: 12px !important;
  }
  
  .csv-input {
  font-size: 12px !important;
  }
  
  .col-md-4-cstm {
  flex: 0 0 33.33333% !important;
  max-width: 33.33333% !important;
  }
  
  .midle-heading {
  margin-bottom: 0px !important;
  }
  
  .cstm-top {
  top: 70px !important;
  }
  
  .form-control {
  font-size: 12px !important
  }
  
  .btn-import {
  font-size: 12px !important
  }
  
  .btn-importcampaign {
  font-size: 12px !important
  }
  
  .btn-generate {
  font-size: 12px !important
  }
  
  .col-md-12-cstm {
  flex: 0 0 100% !important;
  max-width: 100% !important;
  }
  
  .col-md-5-cstm {
  flex: 0 0 41.66667% !important;
  max-width: 41.66667% !important;
  }
  
  .col-md-7-cstm {
  flex: 0 0 58.33333% !important;
  max-width: 58.33333% !important;
  }
  
  .sidebar {
  width: 16rem !important;
  }
  
  .btn-generate {
  /* background-color: rgb(99, 25, 184) !important; */
  width: 150px !important;
  height: 38px !important;
  }
  
  .margin-cstm-12 {
  margin-left: 12px !important;
  }
  
  .btn-primary {
  font-size: 12px !important;
  height: 36px !important;
  }
  
  .cstm-float {
  float: right !important;
  width: 115px !important
  }
  
  .col-md-10-cstm {
  flex: 0 0 83.33333% !important;
  max-width: 83.33333% !important;
  }
  
  .common-btn {
  font-size: 12px !important;
  height: 36px !important;
  }
  
  .cancel-btn {
  font-size: 12px !important;
  height: 36px !important;
  
  }
  
  .justify-content-cstm {
  justify-content: space-between !important;
  }
  
  .cstm-font {
  font-size: 15px !important;
  
  }
  }
  
  .datepicker-cursor {
  cursor: pointer !important;
  pointer-events: all;
  }
  
  
  @media (min-width: 760px) and (-webkit-device-pixel-ratio: 1.25) {
    .importedArrow{
      font-size: 11px !important;
      padding-left: 0px !important;
    }
    .importArrow{
      font-size: 11px !important;
    }
    .notReadyIcon {
      font-size: 11px !important;
      padding-left: 10px;
  }

    .disableddeleteIcon{
      font-size: 11px !important;
      padding-left:13px;
      color:gray
    }

    .deleteIcon{
      font-size: 11px !important;
      padding-left: 13px;
    }
    .readyIcon{
      font-size: 11px !important;
      padding-left: 10px;
    }

    .moreOptionsHorizon {
      justify-content: center;
      display: flex;
      font-size: 10px !important;
      right: 0px;
      position: absolute;
      background-color: white;
      z-index: 9;
      width: 100%!important ;
    }
    .disabledimportArrow{
      font-size: 11px !important;
      padding-left: 0px;
    }

    .Suggestion-mainDiv {
       background: #fff;
       max-height: 608px;
       overflow-y: auto;
       /* position: fixed!important;
       right: 14%;
       top: 14%;
       width: 18%!important; */
      }
      .Main-HintMngmntDiv {
       max-height: 602px;
       overflow-y: auto;
      }
      
    
  .cstm-margin {
  margin-top: 5rem !important;
  }
  
  /* .cstm-margin-right{
  margin-right: 20px !important;
  } */
  .m-top {
  margin-top: 0px !important;
  }
  
  .logo-deposit {
  width: 235px !important;
  height: 200px !important;
  }
  
  .row-flex {
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  }
  
  .transition {
  opacity: 1;
  transition: 1.2s all ease !important;
  transform: translatex(-200px) !important;
  }
  
  .frame {
  
  width: 100%;
  height: 200px;
  
  position: relative;
  }
  
  .cstm-title {
  /* width: 225px; */
  height: 52px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: auto !important;
  }
  
  .p-overlay {
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid black;
  padding-bottom: 0;
  margin-bottom: 0px !important;
  }
  
  .p-overlay-before {
  font-size: 16px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  border: 0;
  outline: 0;
  background: transparent;
  padding-bottom: 0;
  display: inline-block;
  margin-bottom: 0px !important;
  
  }
  
  .cstm-textarea {
  left: 25px;
  top: 309px;
  font-size: 12px !important;
  border: 0;
  outline: 0;
  background: transparent;
  margin-bottom: 10px;
  border-bottom: 1px solid black;
  /* position: absolute; */
  width: 100% !important;
  
  height: inherit;
  }
  
  .span-cstm1 {
  right: 0px !important;
  position: absolute;
  bottom: 0px;
  border: 0px !important;
  /* background-color: #6319b8 !important; */
  width: 25px;
  height: 25px !important;
  padding-left: 8px !important;
  padding-top: 4px !important;
  color: #fff !important;
  font-size: 12px !important;
  }
  
  .span-cstm1Un {
  right: 0px !important;
  position: absolute;
  bottom: 0px;
  border: 0px !important;
  /* background-color: #6319b8 !important; */
  width: 25px;
  height: 25px !important;
  padding-left: 8px !important;
  padding-top: 4px !important;
  color: #fff !important;
  font-size: 12px !important;
  }
  
  .span-cstm1Dwnld {
  right: 0px !important;
  position: absolute;
  bottom: 0px;
  border: 0px !important;
  /* background-color: #6319b8 !important; */
  width: 25px;
  height: 25px !important;
  padding-left: 8px !important;
  padding-top: 4px !important;
  color: #fff !important;
  font-size: 12px !important;
  }
  
  .span-cstm1Sug {
  right: 0px !important;
  position: absolute;
  bottom: 0px;
  border: 0px !important;
  /* background-color: #6319b8 !important; */
  width: 25px;
  height: 25px !important;
  padding-left: 8px !important;
  padding-top: 4px !important;
  color: #fff !important;
  font-size: 12px !important;
  }
  
  .done-btn-s {
  background: #278d3b !important;
  z-index: 99999;
  position: absolute;
  float: right;
  top: -24px;
  border: 0px !important;
  
  }
  
  .btn-imports {
  width: 130px !important;
  font-size: 12px !important;
  height: 35px !important;
  }
  
  .p-deposit {
  max-height: 35px;
  
  /* width: 10em; */
  overflow: hidden;
  /* make sure it hides the content that overflows */
  /* white-space: nowrap; don't break the line */
  /* text-overflow: ellipsis; give the beautiful '...' effect */
  font-size: 12px !important;
  }
  
  .btn-generate {
  /* background-color: rgb(99, 25, 184) !important; */
  width: 145px !important;
  height: 38px !important;
  }
  
  .cstm-description {
  /* width: 255px; */
  height: 42px;
  width: auto !important;
  }
  
  .btn-importgoogle {
  width: 145px !important;
  height: 38px !important;
  /* background-color: rgb(99, 25, 184) !important; */
  }
  
  .col-md-8-cstms {
  flex: 0 0 66.66667% !important;
  max-width: 66.66667% !important;
  }
  
  .btn-export {
  width: 145px !important;
  font-size: 12px !important;
  height: 35px !important;
  }
  
  .sidebar .nav-item .nav-link span {
  font-size: 12px !important;
  }
  
  .btn-importapple {
  width: 145px !important;
  height: 38px !important;
  /* background-color: rgb(99, 25, 184) !important; */
  }
  
  .sidebar {
  width: 14rem !important;
  }
  
  .logo img {
  width: 190px;
  }
  
  body {
  font-size: 12px !important;
  }
  
  .common-btn {
  font-size: 12px !important;
  height: 36px !important;
  }
  
  .cancel-btn {
  font-size: 12px !important;
  height: 36px !important;
  
  }
  
  .btn-primary {
  font-size: 12px !important;
  height: 36px !important;
  }
  
  .btn-generate {
  font-size: 12px !important
  }
  
  .form-control {
  font-size: 12px !important
  }
  
  .form-search {
  height: 36px !important;
  font-size: 12px !important;
  }
  
  h5 {
  font-size: 20px !important;
  }
  
  .csv-input {
  font-size: 12px !important;
  }
  
  .col-md-4-cstm {
  flex: 0 0 33.33333% !important;
  max-width: 33.33333% !important;
  }
  
  .midle-heading {
  margin-bottom: 0px !important;
  }
  
  #loader {
  left: 12rem;
  width: 80px;
  height: 80px;
  border: 12px solid #f3f3f3;
  border-radius: 50%;
  /* border-top: 12px solid #481296; */
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
  position: fixed;
  z-index: 999;
  overflow: show;
  margin: auto;
  top: 0rem !important;
  bottom: 0;
  right: 0;
  }
  
  @-webkit-keyframes spin {
  0% {
  -webkit-transform: rotate(0deg);
  }
  
  100% {
  -webkit-transform: rotate(360deg);
  }
  }
  
  @keyframes spin {
  0% {
  transform: rotate(0deg);
  }
  
  100% {
  transform: rotate(360deg);
  }
  }
  
  /* Add animation to "page content" */
  .animate-bottom {
  position: relative;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 1s;
  animation-name: animatebottom;
  animation-duration: 1s
  }
  
  @-webkit-keyframes animatebottom {
  from {
  bottom: -100px;
  opacity: 0
  }
  
  to {
  bottom: 0px;
  opacity: 1
  }
  }
  
  @keyframes animatebottom {
  from {
  bottom: -100px;
  opacity: 0
  }
  
  to {
  bottom: 0;
  opacity: 1
  }
  }
  
  .static-top {
  width: 83% !important;
  position: fixed !important;
  z-index: 99 !important;

  }
  
  .col-md-12-cstm {
  flex: 0 0 100% !important;
  max-width: 100% !important;
  }
  
  .col-md-5-cstm {
  flex: 0 0 41.66667% !important;
  max-width: 41.66667% !important;
  }
  
  .col-md-7-cstm {
  flex: 0 0 58.33333% !important;
  max-width: 58.33333% !important;
  }
  
  .text-p {
  font-size: 12px !important;
  }
  
  .edit-btn {
  /* color: #6319b8 !important; */
  font-size: 14px !important;
  cursor: pointer;
  }
  
  .edit-btn-disabled {
  color: #9b9a9a !important;
  
  font-size: 14px !important;
  }
  
  .delete-btn {
  color: #c1272d !important;
  font-size: 14px !important;
  cursor: pointer;
  }
  
  .table-td td {
  padding-top: 22px !important;
  padding-bottom: 22px !important;
  vertical-align: middle !important;
  color: #706f6f !important;
  }
  
  .bg-table {
  /* background-color: #6319b8; */
  color: #fff;
  height: 55px;
  }
  
  }
  
  @media (max-width:1280px) and (min-width:1390px) {
  .static-top {
  width: 81% !important;
  position: fixed !important;
  z-index: 99 !important;
  }
  }
  
  .wordbreak {
  word-break: break-word !important;
  }
  
  
  
  .css-1s2u09g-control {
  cursor: pointer !important;
  }
  
  .cstm-color-select {
  /* border-bottom: 1px dotted rgb(34, 28, 28) !important; */
  color: rgb(30, 29, 30) !important;
  
  }
  
  .css-12jo7m5 {
  border-radius: 2px;
  color: hsl(0deg 0% 100%) !important;
  font-size: 85%;
  overflow: hidden;
  padding: 3px;
  padding-left: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  }
  
  .css-1rhbuit-multiValue {
  background-color: rgb(107 81 183) !important;
  border-radius: 15% !important;
  /* display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox; */
  display: flex;
  margin: 12px;
  min-width: 0;
  box-sizing: border-box;
  }
  
  
  
  .ratings {
  margin-right: 10px;
  cursor: pointer;
  }
  
  .ratings i {
  
  color: #cecece;
  font-size: 32px;
  }
  
  .rating-color {
  color: #fbc634 !important;
  }
  
  .rating-color2 {
  color: #fbc634 !important;
  
  }
  
  .review-count {
  font-weight: 400;
  margin-bottom: 2px;
  font-size: 24px !important;
  }
  
  .small-ratings i {
  color: #cecece;
  }
  
  .review-stat {
  font-weight: 300;
  font-size: 18px;
  margin-bottom: 2px;
  }
  
  .content {
  text-align: center;
  }
  
  .content h1 {
  font-family: 'Sansita', sans-serif;
  letter-spacing: 1px;
  font-size: 50px;
  color: #282828;
  margin-bottom: 10px;
  }
  
  .content i {
  color: #FFC107;
  }
  
  .content span {
  position: relative;
  display: inline-block;
  }
  
  .content span:before,
  .content span:after {
  position: absolute;
  content: "";
  background-color: #282828;
  width: 40px;
  height: 2px;
  top: 40%;
  }
  
  .content span:before {
  left: -45px;
  }
  
  .content span:after {
  right: -45px;
  }
  
  .content p {
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  letter-spacing: 1px;
  }
  
  .form-control-cstm:focus {
  color: #6e707e;
  background-color: #fff;
  border-color: #bac8f3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(78 115 223 / 25%);
  }
  
  .block {
  /* border: 1px solid grey; */
  padding: 10px;
  margin: 10px;
  }
  
  ul.rating {
  margin: 0;
  padding-left: 28px;
  list-style: none;
  }
  
  ul.rating li {
  margin: 0;
  padding: 0;
  display: inline-block;
  }
  
  ul.rating li button {
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  background: transparent;
  display: inline-block;
  font-size: x-large;
  color: darkorange;
  }
  
  .disabled-star {
  color: rgb(99, 98, 98) !important;
  fill: #3a3a3a !important;
  }
  
  .form-control-cstm:focus {
  color: #6e707e;
  background-color: #e8e8f7 !important;
  border-color: #e8e8f7 !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(78 115 223 / 25%);
  }
  
  .card1 {
  
  display: flex;
  flex-wrap: wrap;
  }
  
  .image1 {
  flex: 1 1 120px;
  }
  
  .content1 {
  flex: 1 1 200px
  }
  
  .done-btn-s {
  background: #278d3b !important;
  z-index: 99999;
  position: absolute;
  float: right;
  top: -24px;
  border: 0px !important;
  
  }
  
  .cstm-textarea {
  left: 25px;
  top: 309px;
  font-size: 14px;
  border: 0;
  outline: 0;
  background: transparent;
  margin-bottom: 10px;
  border-bottom: 1px solid black;
  /* position: absolute; */
  width: 100% !important;
  
  height: inherit;
  }
  
  .green-btn {
  background-color: #278d3b !important
  }
  
  .border-none {
  border: 0px !important;
  background-color: #fff !important;
  overflow: hidden;
  }
  
  @media (max-width:1450px) and (min-width:1400px) {
  .btn-importgoogle {
  width: 169px !important;
  height: 38px !important;
  /* background-color: rgb(99, 25, 184) !important; */
  }
  
  .btn-importapple {
  width: 165px !important;
  height: 38px !important;
  /* background-color: rgb(99, 25, 184) !important; */
  }
  
  .row-flex {
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  }
  
  .cstm-col3-del {
  flex: 0 0 25% !important;
  max-width: 25% !important;
  }
  
  #loader {
  left: 15rem;
  width: 80px;
  height: 80px;
  border: 12px solid #f3f3f3;
  border-radius: 50%;
  /* border-top: 12px solid #481296; */
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
  position: fixed;
  z-index: 999;
  overflow: show;
  margin: auto;
  top: 5rem !important;
  bottom: 0;
  right: 0;
  }
  
  .cstm-col5-del {
  flex: 0 0 41.66667% !important;
  max-width: 41.66667% !important;
  }
  
  .cstm-col7-template {
  flex: 0 0 58.33333% !important;
  ;
  max-width: 58.33333% !important;
  }
  
  .cstm-col5-template {
  flex: 0 0 41.66667% !important;
  max-width: 41.66667% !important;
  }
  
  .frame {
  
  width: 100%;
  height: 250px;
  position: relative;
  }
  .Suggestion-mainDiv{
    overflow-y: auto;
    max-height: 715px;
    /* top: 15% !important; */
    /* position: fixed !important;
    right: 13% !important;
    width: 20% !important; */
    background: white;
  }
  .Main-HintMngmntDiv{
    max-height: 735px;
    overflow-y: auto;
  }


  }
  
  @media (max-width:1399px) and (min-width:1320px) {
  .cstm-col7-template {
  flex: 0 0 58.33333% !important;
  ;
  max-width: 58.33333% !important;
  }
  
  .cstm-col5-template {
  flex: 0 0 41.66667% !important;
  max-width: 41.66667% !important;
  }
  
  }
  
  .hint_image {
  height: 100%;
  width: 100%;
  }
  
  .react-stars {
  margin: 0 !important;
  padding: 0 !important;
  background: transparent !important;
  color: darkorange !important;
  display: inline-block !important;
  font-size: x-large !important;
  }
  
  .terms-bold {
  font-weight: bolder;
  padding-bottom: 15px;
  padding-top: 15px;
  
  }
  
  .terms-right {
  margin-bottom: 25px;
  text-align: end;
  }
  
  .terms-bottom {
  
  margin-bottom: 30px;
  
  }
  
  .terms-bottom1 {
  padding-bottom: 20px;
  }
  
  .pr-table {
  border: 2px black solid !important;
  padding: 8px;
  width: 320px;
  }
  
  .pr-list {
  list-style: none !important;
  }
  
  .pr-href {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  }
  
  @media(max-width:768px) {
  .pr-table {
  font-weight: 700px !important;
  font-size: 12px;
  word-break: keep-all;
  border: 2px black solid !important;
  padding: 8px;
  }
  
  .pr-href-break {
  word-break: break-all;
  }
  }
  
  @media(min-width:999px) {
  .pr-table {
  font-weight: 700px !important;
  word-break: keep-all;
  border: 2px black solid !important;
  padding: 8px;
  }
  
  .pr-margin-left {
  margin-left: 20px !important;
  }
  }
  
  @media(min-width:820px) {
  .pr-table {
  margin-left: 20px !important;
  font-weight: 700px !important;
  font-size: 14px;
  word-break: keep-all;
  border: 2px black solid !important;
  padding: 8px;
  }
  
  }
  
  @media(max-width:360px) {
  .pr-href {
  font-size: 10px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  }
  }
  
  @media(min-width:270px) and (max-width:300px) {
  .pr-table {
  margin-left: -10px !important;
  word-break: break-all;
  width: auto !important;
  }
  
  .pr-href-break-ja {
  word-break: break-all;
  }
  }
  
  @media(min-width:412px) {
  .pr-table {
  margin-left: 0px !important;
  font-weight: 700px !important;
  font-size: 13px;
  word-break: keep-all;
  border: 2px black solid !important;
  padding: 8px;
  }
  }
  
  @media(min-width:375px) {
  .pr-table {
  margin-left: 0px !important;
  font-weight: 700px !important;
  font-size: 11px;
  word-break: keep-all;
  border: 2px black solid !important;
  padding: 8px;
  }
  }
  
  @media(min-width:414px) {
  .pr-table {
  margin-left: 20px !important;
  font-weight: 700px !important;
  font-size: 12px;
  word-break: keep-all;
  border: 2px black solid !important;
  padding: 8px;
  }
  }
  
  @media(min-width:375px) {
  .pr-table {
  margin-left: 0px !important;
  font-weight: 700px !important;
  font-size: 11px;
  word-break: keep-all;
  border: 2px black solid !important;
  padding: 8px;
  }
  }
  
  .cstm-newcheckbox {
  padding-right: 20px;
  padding-left: 20px;
  }
  
  .cstm-subcheckbox {
  width: 30px;
  height: 30px;
  }
  
  .cstm-checboxdiv {
  margin-bottom: 15px;
  text-align: left !important;
  }
  
  .mycontent-left {
  
  border-right: 1px solid rgb(208, 194, 194);
  height: 100%;
  }
  
  .imageText-centered {
  font-size: 24px;
  height: 35px;
  padding-bottom: 5px;
  width: 100%;
  z-index: 9;
  color: white;
  position: absolute;
  bottom: 1%;
  left: 0%;
  background-color: rgb(73, 70, 70) !important;
  opacity: .6;
  font-weight: bolder;
  }
  
  .wordText-centered {
  font-weight: bolder;
  font-size: 24px;
  }
  
  .wordText-centeredpos {
  font-size: 15px;
  }
  
  .wordText-centeredjap {
  font-size: 15px;
  }
  
  .cstm-imgGalleryCard {
  display: flex;
  }
  
  .textDiv {
  width: 100%;
  z-index: 9;
  color: white;
  position: absolute;
  bottom: 0;
  left: 0%;
  background-color: rgb(73, 70, 70) !important;
  opacity: .6;
  font-weight: bolder;
  }
  
  .selectedSugDesc {
  height: 40px;
  padding-bottom: 37px;
  position: absolute;
  top: 107px;
  left: 3px;
  }
  .moreOptions {
    border-radius: 7px;
    right: 28px;
    position: absolute;
    background-color:white;
    z-index: 9;
    width: 145px;
    display:none;
    padding-top:10px;
    padding-bottom:5px
}
.moreOptionsHorizon {
 
  justify-content: center;
  display: flex;
  padding-top: 7px;
  padding-bottom: 4px;
  font-size: 10px !important;
  right: 0px;
  position: absolute;
  background-color: white;
  z-index: 9;
  width: 100%;
}
.cstm-linkWord{
  cursor: pointer !important;
  color: blue !important;
  
}

/* .cstm-linkWord:hover{
font-size: 12px;
  cursor: pointer !important;
  color: rgba(40, 40, 45, 0.671) !important;
  font-weight: bolder;

} */



.disabledimportArrow{
  font-size: 14px;
  padding-left: 0px;
}
.disableddeleteIcon{
  padding-left:10px;
  color:gray;
  font-size: 14px;
}
.importArrow{
  font-size: 14px;
}
.importedArrow{
  font-size: 14px;
}
.deleteIcon{
  font-size: 14px;
  padding-left: 10px;
}
.readyIcon{
  font-size: 14px;
  padding-left: 10px;
}
.notReadyIcon{
  font-size: 14px;
  padding-left: 10px;
}
.newToprodIcon{
  font-size: 14px;
  padding-left:10px;
  /* color:blue */
}
.prodIcon{
  font-size: 14px;
  padding-left:7px;
  /* color:blue */
}
@media (max-width:1390px) and (min-width:1280px) {
  .importedArrow{
    font-size: 10px !important;
    padding-right: 7px !important;
  }
  .importArrow{
    padding-right: 7px !important;
    font-size: 10px !important;
  }
  .notReadyIcon {
    font-size: 10px !important;
    padding-left: 0px;
}

  .disableddeleteIcon{
    font-size: 10px !important;
    padding-right:7px;
   
    color:gray
  }

  .deleteIcon{
    padding-left: 0px !important;
    font-size: 10px !important;
    padding-right: 7px !important;
  }
  .readyIcon{
    font-size: 10px !important;
    padding-left: 0px;
  }

  .moreOptionsHorizon {
    justify-content: center;
    display: flex;
    font-size: 10px !important;
    right: 0px;
    position: absolute;
    background-color: white;
    z-index: 9;
    width: 100%!important ;
  }
  .disabledimportArrow{
    font-size: 10px !important;
    padding-left: 0px;
  }
  .delete-btn {
    padding-left: 0px !important;
    color: #c1272d !important;
    font-size: 10px !important;
    cursor: pointer;
    }
    .edit-btn {
      /* color: #6319b8 !important; */
      font-size: 10px !important;
      cursor: pointer;
  }
}
.cstm-height{
  height: 40px;
}