@font-face{
  font-family: "KozGoProBold";
  src: url("./fonts/KozGoPro-Medium.otf") format("truetype");
}
.main {
  display: flex;
  flex-direction: column;
  background-color: white;
}

.main.layout {
  position: relative;
  overflow: hidden;
}

.section1__section1 {
  display: flex;
  flex-direction: column;
}

.section1__section1.layout {
  position: relative;
}

.section1__flex4 {
  display: flex;
  justify-content: center;
}
.section1__image15d {
  width: 100%;
  height: auto;
  vertical-align: top;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center top;
  object-position: center top;
}





.section1__flex4.layout3 {
  position: relative;
  flex-grow: 1;
  /* margin: 3.8px 0px 52.81px; */
}

.section1__section1__flex4 > .item {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 1 1280px;
}


.section1__group {
  display: flex;
  flex-direction: column;
}

.section1__group.layout4 {
  position: relative;
  flex-grow: 1;
}

.section1__flex {
  display: flex;
  flex-direction: column;
}

.section1__flex.layout2 {
  position: relative;
  flex-grow: 1;
}

.section1__flex.layout3 {
  position: relative;
}

.section1__block {
  display: flex;
  flex-direction: column;
  background-color: #6319b8;
}

.section1__block.layout {
  position: relative;
  overflow: hidden;
}

.section1__flex1 {
  display: flex;
}

.section1__flex1.layout {
  position: relative;
  flex-grow: 1;
  width: 100%;
  margin: 47px 0% 0 5%;
  padding-right: 5%;
  justify-content: space-evenly;
}

.section1__section1__flex1 > .item {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 1 658px;
}

.section1__flex.layout1 {
  position: relative;
  flex-grow: 1;
  margin: 0px 0px 0;
}

.section1__image {
  width: 100%;
  height: auto;
  vertical-align: top;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center top;
  object-position: center top;
}

.section1__image.layout {
  position: relative;
  flex: 1 1 auto;
  width: 31.61%;
  margin: 0px 0% 0px -3%;
  transform: scale(1.3);
}

.section1__flex.layout {
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  margin: 110px 0% 0px 0%;
}

.section1__hero_title {
  display: flex;
  font: 700 50px/1.2 "KozGoProBold", Helvetica, Arial, serif;
  color: white;
  letter-spacing: 0px;
}
/* @media (min-width: 1919px) {
  .section1__hero_title {
    justify-content: center;
    text-align: center;
  }
} */
/* @media (min-width: 1199px) {
  .section1__hero_title {
    font-size: 43px;
  }
} */
/* @media (min-width: 991px) {
  .section1__hero_title {
    font-size: 37px;
  }
} */
/* @media (min-width: 767px) {
  .section1__hero_title {
    font-size: 32px;
  }
} */
/* @media (min-width: 575px) {
  .section1__hero_title {
    font-size: 30px;
  }
} */
/* @media (min-width: 479px) {
  .section1__hero_title {
    font-size: 27px;
  }
} */
/* @media (min-width: 383px) {
  .section1__hero_title {
    font-size: 25px;
  }
} */

.section1__hero_title.layout {
  position: relative;
  width: 100%;
  margin: 0px 0% 0px 0%;
}

.section1__hero_title1 {
  font: 700 50px/1.2 "KozGoProBold", Helvetica, Arial, serif;
  color: white;
  letter-spacing: 0px;
}
/* @media (min-width: 1199px) {
  .section1__hero_title1 {
    font-size: 43px;
    text-align: left;
  }
} */
/* @media (min-width: 991px) {
  .section1__hero_title1 {
    font-size: 37px;
  }
} */
/* @media (min-width: 767px) {
  .section1__hero_title1 {
    font-size: 32px;
  }
} */
/* @media (min-width: 575px) {
  .section1__hero_title1 {
    font-size: 30px;
  }
} */
/* @media (min-width: 479px) {
  .section1__hero_title1 {
    font-size: 27px;
  }
}
@media (min-width: 383px) {
  .section1__hero_title1 {
    font-size: 25px;
  }
} */

.section1__hero_title1.layout {
  position: relative;
  margin: 78px 0px 0px;
}

.section1__section1__flex1 > .spacer {
  flex: 0 1 76px;
}
/* @media (min-width: 1199px) {
  .section1__section1__flex1 > .spacer {
    display: none;
  }
} */

.section1__section1__flex1 > .item1 {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 1 505px;
}
/* @media (min-width: 1199px) {
  .section1__section1__flex1 > .item1 {
    flex: 0 0 100%;
  }
} */


.section1__image1 {
  width: auto;
  height: auto;
  vertical-align: bottom;
  object-position: center top;
}

.section1__section1__flex > .item {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0 0 auto;
  min-height: 318px;
}

.section1__block1 {
  display: flex;
  flex-direction: column;
  background-color: whitesmoke;
}

.section1__block1.layout {
  padding: 0 5% 2% 5%;
  /* padding-bottom: 20px; */
  position: relative;
  overflow: hidden;
  flex-grow: 1;
}

.section1__hero_title11 {
  display: flex;
  justify-content: center;
  font: 700 50px/1.2 "KozGoProBold", Helvetica, Arial, serif;
  color: #1b1b1b;
  text-align: center;
  letter-spacing: 0px;
}
/* @media (min-width: 2999px) {
  .section1__hero_title11 {
    text-align: right;
  }
}
@media (min-width: 1399px) {
  .section1__hero_title11 {
    text-align: justify;
  }
}
@media (min-width: 1199px) {
  .section1__hero_title11 {
    font-size: 43px;
  }
}
@media (min-width: 991px) {
  .section1__hero_title11 {
    font-size: 37px;
  }
}
@media (min-width: 767px) {
  .section1__hero_title11 {
    font-size: 32px;
  }
}
@media (min-width: 575px) {
  .section1__hero_title11 {
    font-size: 30px;
  }
}
@media (min-width: 479px) {
  .section1__hero_title11 {
    font-size: 27px;
  }
}
@media (min-width: 383px) {
  .section1__hero_title11 {
    font-size: 25px;
  }
} */

.section1__hero_title11.layout {
  position: relative;
  width: 100%;
  margin: 55px 22.26% 5px 0;
}
/* @media (min-width: 991px) {
  .section1__hero_title11.layout {
    width: 63.64%;
    margin: 55px 16.63% 0px 19.73%;
  }
}
@media (min-width: 767px) {
  .section1__hero_title11.layout {
    width: 70%;
    margin: 55px 13.72% 0px 16.28%;
  }
}
@media (min-width: 575px) {
  .section1__hero_title11.layout {
    width: 75.68%;
    margin: 55px 11.13% 0px 13.2%;
  }
}
@media (min-width: 479px) {
  .section1__hero_title11.layout {
    width: 80.58%;
    margin: 55px 8.88% 0px 10.54%;
  }
}
@media (min-width: 383px) {
  .section1__hero_title11.layout {
    width: 84.69%;
    margin: 55px 7% 0px 8.31%;
  }
} */

.section1__medium_title {
  display: flex;
  justify-content: center;
  font: 400 30px/1.2 "KozGoProBold", Helvetica, Arial, serif;
  color: #222222;
  text-align: center;
  letter-spacing: 0px;
}
/* @media (min-width: 1199px) {
  .section1__medium_title {
    font-size: 22px;
    text-align: right;
  }
}
@media (min-width: 991px) {
  .section1__medium_title {
    font-size: 20px;
  }
}
@media (min-width: 767px) {
  .section1__medium_title {
    font-size: 19px;
  }
}
@media (min-width: 575px) {
  .section1__medium_title {
    font-size: 18px;
  }
}
@media (min-width: 479px) {
  .section1__medium_title {
    font-size: 17px;
  }
}
@media (min-width: 383px) {
  .section1__medium_title {
    font-size: 16px;
  }
} */

.section1__medium_title.layout {
  position: relative;
  width: 100%;
  margin: 0px auto;
}
/* @media (min-width: 767px) {
  .section1__medium_title.layout {
    width: 56.54%;
  }
}
@media (min-width: 575px) {
  .section1__medium_title.layout {
    width: 63.43%;
  }
}
@media (min-width: 479px) {
  .section1__medium_title.layout {
    width: 69.82%;
  }
}
@media (min-width: 383px) {
  .section1__medium_title.layout {
    width: 75.52%;
  }
} */

.section1__flex2 {
  display: flex;
}
/* @media (min-width: 991px) {
  .section1__flex2 {
    flex-wrap: wrap;
    align-content: flex-start;
    -moz-column-gap: 0px;
    column-gap: 0px;
    row-gap: 16px;
  }
} */

.section1__flex2.layout {
  margin-top: 25px;
  position: relative;
  height: min-content;
  width: 100%;
  justify-content: center;
  /* margin: 46px 46.7% 31px 34.49%; */
}
/* @media (min-width: 991px) { */
  /* .section1__flex2.layout {
    width: 26.49%;
    margin: 46px 42.28% 31px 31.23%;
  }
}
@media (min-width: 767px) {
  .section1__flex2.layout {
    width: 32.45%;
    margin: 46px 38.85% 31px 28.7%;
  }
}
@media (min-width: 575px) {
  .section1__flex2.layout {
    width: 39.04%;
    margin: 46px 35.06% 31px 25.9%;
  }
}
@media (min-width: 479px) {
  .section1__flex2.layout {
    width: 46.06%;
    margin: 46px 31.03% 31px 22.91%;
  }
} */
/* @media (min-width: 383px) {
  .section1__flex2.layout {
    width: 53.24%;
    margin: 46px 26.9% 31px 19.86%;
  }
} */

.section1__section1__flex2 > .item {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0 0 auto;
  min-width: 207px;
}
/* @media (min-width: 991px) {
  .section1__section1__flex2 > .item {
    flex: 0 0 100%;
    min-width: unset;
  }
} */

.section1__block2 {
  display: flex;
  background: var(--src) center 100%/contain no-repeat;
}

.section1__block2.layout {
  position: relative;
  height: 76px;
  width: 250px;
  min-width: 207px;
}

.section1__section1__block2 > .item {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0 1 53px;
}

.section1__group.layout {
  position: relative;
  flex-grow: 1;
  margin: 17px 0px 18px 13px;
}

.section1__image13 {
  width: 100%;
  height: auto;
  vertical-align: top;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center top;
  object-position: center top;
}

.section1__image13.layout {
  position: relative;
  width: 8px;
  min-width: 8px;
  margin: 0px 9px 0px 17px;
}

.section1__image12 {
  width: 100%;
  height: auto;
  vertical-align: top;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center top;
  object-position: center top;
}

.section1__image12.layout {
  position: relative;
  width: 34px;
  min-width: 34px;
  margin: 1px 0px 0px;
}

.section1__section1__block2 > .spacer {
  flex: 0 1 12px;
}

.section1__section1__block2 > .item1 {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0 1 58px;
}

.section1__flex5 {
  display: flex;
  flex-direction: column;
}

.section1__flex5.layout {
  position: relative;
  flex-grow: 1;
  margin: 17px 0px;
}

.section1__group.layout1 {
  position: relative;
  margin: 0px 8px 0px 0px;
}

.section1__flex3 {
  display: flex;
}

.section1__flex3.layout {
  position: relative;
  flex-grow: 1;
}

.section1__section1__flex3 > .item {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0 1 18px;
}

.section1__image3 {
  width: 100%;
  height: auto;
  vertical-align: top;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center top;
  object-position: center top;
}

.section1__image3.layout {
  position: relative;
  width: 18px;
  min-width: 18px;
  margin: 0px 0px 5px;
}

.section1__section1__flex3 > .spacer {
  flex: 0 1 2px;
}

.section1__section1__flex3 > .item1 {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0 1 14px;
}

.section1__image4 {
  width: 100%;
  height: auto;
  vertical-align: top;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center top;
  object-position: center top;
}

.section1__image4.layout {
  position: relative;
  width: 14px;
  min-width: 14px;
  margin: 5px 0px 0px;
}

.section1__section1__flex3 > .spacer1 {
  flex: 0 1 3px;
}

.section1__section1__flex3 > .item2 {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0 1 13px;
}

.section1__image5 {
  width: 100%;
  height: auto;
  vertical-align: top;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center top;
  object-position: center top;
}

.section1__image5.layout {
  position: relative;
  width: 13px;
  min-width: 13px;
  margin: 5px 0px 0px;
}

.section1__flex9 {
  display: flex;
}

.section1__flex9.layout {
  position: relative;
  margin: 2px 0px 0px;
}

.section1__section1__flex9 > .item {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0 1 14px;
}

.section1__icon {
  width: 100%;
  height: auto;
  vertical-align: top;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center top;
  object-position: center top;
}

.section1__icon.layout {
  position: relative;
  width: 14px;
  min-width: 14px;
  margin: 1px 0px 0px;
}

.section1__section1__flex9 > .spacer {
  flex: 0 1 3px;
}

.section1__section1__flex9 > .item1 {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0 1 11px;
}

.section1__image10 {
  width: 100%;
  height: auto;
  vertical-align: top;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center top;
  object-position: center top;
}

.section1__image10.layout {
  position: relative;
  width: 11px;
  min-width: 11px;
  margin: 1px 0px 0px;
}

.section1__image8 {
  width: 100%;
  height: 90px;
  vertical-align: top;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center top;
  object-position: center top;
}

.section1__image8.layout {
  position: relative;
  width: 14px;
  min-width: 14px;
}

.section1__section1__flex9 > .spacer1 {
  flex: 0 1 2px;
}

.section1__section1__block2 > .item2 {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0 1 16px;
}

.section1__group.layout2 {
  position: relative;
  flex-grow: 1;
  margin: 16px 0px 17px;
}

.section1__image6 {
  width: 100%;
  height: auto;
  vertical-align: top;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center top;
  object-position: center top;
}

.section1__image6.layout {
  position: relative;
  width: 16px;
  min-width: 16px;
}

.section1__icon1 {
  width: 100%;
  height: auto;
  vertical-align: top;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center top;
  object-position: center top;
}

.section1__icon1.layout {
  position: relative;
  width: 12px;
  min-width: 12px;
  margin: 10px 0px 0px 4px;
}

.section1__section1__block2 > .spacer1 {
  flex: 0 1 1px;
}

.section1__section1__block2 > .item3 {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0 1 34px;
}

.section1__flex6 {
  display: flex;
  flex-direction: column;
}

.section1__flex6.layout {
  position: relative;
  flex-grow: 1;
  margin: 19px 0px 18px;
}

.section1__flex7 {
  display: flex;
}

.section1__flex7.layout {
  position: relative;
}

.section1__section1__flex7 > .item {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0 1 8px;
}

.section1__image7 {
  width: 100%;
  height: auto;
  vertical-align: top;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center top;
  object-position: center top;
}

.section1__image7.layout {
  position: relative;
  width: 8px;
  min-width: 8px;
}

.section1__section1__flex7 > .spacer {
  flex: 0 1 2px;
}

.section1__section1__flex7 > .item1 {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0 1 14px;
}

.section1__image8.layout1 {
  position: relative;
  width: 14px;
  min-width: 14px;
  margin: 3px 0px 0px;
}

.section1__section1__flex7 > .item2 {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
}

.section1__image9 {
  width: 100%;
  height: auto;
  vertical-align: top;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center top;
  object-position: center top;
}

.section1__image9.layout {
  position: relative;
  width: 8px;
  min-width: 8px;
  margin: 3px 0px 0px;
}

.section1__flex8 {
  display: flex;
}

.section1__flex8.layout {
  position: relative;
  margin: 10px 2px 0px;
}

.section1__section1__flex8 > .item {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0 1 11px;
}

.section1__icon2 {
  width: 100%;
  height: auto;
  vertical-align: top;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center top;
  object-position: center top;
}

.section1__icon2.layout {
  position: relative;
  width: 11px;
  min-width: 11px;
}

.section1__section1__flex8 > .spacer {
  flex: 0 1 4px;
}

.section1__section1__flex8 > .item1 {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0 1 12px;
}

.section1__box {
  background-color: #eaeaea;
}

.section1__box.layout {
  position: relative;
  height: 2px;
  margin: 4px 0px 5px;
}

.section1__section1__block2 > .item4 {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0 1 33px;
}

.section1__group.layout3 {
  position: relative;
  flex-grow: 1;
  margin: 22px 19px 17px 0px;
}

.section1__image11 {
  width: 100%;
  height: auto;
  vertical-align: top;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center top;
  object-position: center top;
}

.section1__image11.layout {
  position: relative;
  width: 8px;
  min-width: 8px;
  margin: 8px 6px 0px 0px;
}

.section1__section1__flex2 > .spacer {
  flex: 0 1 314px;
}
/* @media (min-width: 991px) {
  .section1__section1__flex2 > .spacer {
    display: none;
  }
} */

.section1__section1__flex2 > .item1 {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 1 265px;
}
/* @media (min-width: 991px) {
  .section1__section1__flex2 > .item1 {
    flex: 0 0 100%;
  }
} */

.section1__image14 {
  background: var(--src) center 100%/100% 100% no-repeat;
}

.section1__image14.layout {
  position: relative;
  height: 89px;
  width: 226px;
  min-width: 226px;
  margin: -8px 0px 0px 61px;
}

.section1__flex31 {
  display: flex;
  flex-direction: column;
}

.section1__flex31.layout {
  position: relative;
}

.section1__block3 {
  display: flex;
  justify-content: center;
  background-color: #6319b8;
}
/* @media (min-width: 1199px) {
  .section1__block3 {
    flex-wrap: wrap;
    align-content: flex-start;
    -moz-column-gap: 0px;
    column-gap: 0px;
    row-gap: 16px;
  }
} */

.section1__block3.layout {
  padding: 0% 5% 0% 5%;
  position: relative;
  overflow: hidden;
}
/* @media (min-width: 991px) {
  .section1__block3.layout {
    margin: 0px;
  }
} */

.section1__section1__block3 > .item {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0 0 auto;
  min-width: 509px;
}
/* @media (min-width: 1199px) {
  .section1__section1__block3 > .item {
    flex: 0 0 100%;
    min-width: unset;
  }
} */

.section1__image15 {
  width: 100%;
  height: auto;
  vertical-align: top;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center top;
  object-position: center top;
}

.section1__image15.layout {
  position: relative;
  width: 354px;
  min-width: 354px;
  margin: 193px 0px 0px 84px;
}
/* @media (min-width: 2999px) {
  .section1__image15.layout {
    margin: 193px 0px 0px 59px;
  }
}
@media (min-width: 1199px) {
  .section1__image15.layout {
    width: 345px;
    min-width: 345px;
    margin: 0px;
  }
}
@media (min-width: 991px) {
  .section1__image15.layout {
    width: 278px;
    min-width: 278px;
  }
} */

.section1__section1__block3 > .spacer {
  flex: 0 1 263px;
}
/* @media (min-width: 1199px) { */
  /* .section1__section1__block3 > .spacer {
    display: none;
  }
} */

.section1__section1__block3 > .item1 {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 1 746px;
}
/* @media (min-width: 1199px) {
  .section1__section1__block3 > .item1 {
    flex: 0 0 100%;
  }
} */

.section1__flex21 {
  display: flex;
  flex-direction: column;
}

.section1__flex21.layout {
  position: relative;
  flex-grow: 1;
  margin: 80px 0px -45px 23px;
}
/* @media (min-width: 1199px) {
  .section1__flex21.layout {
    margin: 0px 24px;
  }
}
@media (min-width: 991px) {
  .section1__flex21.layout {
    margin: 0px 20px;
  }
}
@media (min-width: 767px) {
  .section1__flex21.layout {
    margin: 0px 17px;
  }
}
@media (min-width: 575px) {
  .section1__flex21.layout {
    margin: 0px 15px;
  }
}
@media (min-width: 479px) {
  .section1__flex21.layout {
    margin: 0px 14px;
  }
}
@media (min-width: 383px) {
  .section1__flex21.layout {
    margin: 0px 13px;
  }
} */

.section1__hero_title2 {
  font: 700 36px/1.19 "KozGoProBold", Helvetica, Arial, serif;
  color: white;
  letter-spacing: 0px;
}
/* @media (min-width: 1199px) {
  .section1__hero_title2 {
    font-size: 43px;
    text-align: left;
  }
}
@media (min-width: 991px) {
  .section1__hero_title2 {
    font-size: 37px;
  }
}
@media (min-width: 767px) {
  .section1__hero_title2 {
    font-size: 32px;
  }
}
@media (min-width: 575px) {
  .section1__hero_title2 {
    font-size: 30px;
  }
}
@media (min-width: 479px) {
  .section1__hero_title2 {
    font-size: 27px;
  }
}
@media (min-width: 383px) {
  .section1__hero_title2 {
    font-size: 25px;
  }
} */

.section1__hero_title2.layout {
  position: relative;
  flex: 1 1 auto;
}
/* @media (min-width: 1199px) {
  .section1__hero_title2.layout {
    margin: 0px 85px 0px 0px;
  }
} */

.section1__hero_title21 {
  font: 700 30px/1.2 "KozGoProBold", Helvetica, Arial, serif;
  color: #fbff62;
  letter-spacing: 0px;
}
/* @media (min-width: 1199px) {
  .section1__hero_title21 {
    font-size: 32px;
    text-align: left;
  }
}
@media (min-width: 991px) {
  .section1__hero_title21 {
    font-size: 29px;
  }
}
@media (min-width: 767px) {
  .section1__hero_title21 {
    font-size: 25px;
  }
}
@media (min-width: 575px) {
  .section1__hero_title21 {
    font-size: 24px;
  }
}
@media (min-width: 479px) {
  .section1__hero_title21 {
    font-size: 22px;
  }
}
@media (min-width: 383px) {
  .section1__hero_title21 {
    font-size: 21px;
  }
} */

.section1__hero_title21.layout {
  position: relative;
  flex: 1 1 auto;
  margin: 36px 0px 0px;
}

.section1__big_title {
  font: 400 36px/1.2 "KozGoProBold", Helvetica, Arial, serif;
  color: white;
  letter-spacing: 0px;
}
/* @media (min-width: 1199px) {
  .section1__big_title {
    font-size: 29px;
    text-align: left;
  }
}
@media (min-width: 991px) {
  .section1__big_title {
    font-size: 25px;
  }
}
@media (min-width: 767px) {
  .section1__big_title {
    font-size: 23px;
  }
}
@media (min-width: 575px) {
  .section1__big_title {
    font-size: 21px;
  }
}
@media (min-width: 479px) {
  .section1__big_title {
    font-size: 20px;
  }
}
@media (min-width: 383px) {
  .section1__big_title {
    font-size: 19px;
  }
} */

.section1__big_title.layout {
  position: relative;
  flex: 1 1 auto;
  margin: 6px 16px 0px 31px;
}
/* @media (min-width: 1199px) {
  .section1__big_title.layout {
    margin: 6px 16px 0px 27px;
  }
}
@media (min-width: 991px) {
  .section1__big_title.layout {
    margin: 6px 16px 0px 23px;
  }
}
@media (min-width: 767px) {
  .section1__big_title.layout {
    margin: 6px 16px 0px 19px;
  }
}
@media (min-width: 575px) {
  .section1__big_title.layout {
    margin: 6px 16px 0px 18px;
  }
}
@media (min-width: 479px) {
  .section1__big_title.layout {
    margin: 6px 16px 0px;
  }
}
@media (min-width: 383px) {
  .section1__big_title.layout {
    margin: 6px 16px 0px 15px;
  }
} */

.section1__hero_title22 {
  font: 700 30px/1.19 "KozGoProBold", Helvetica, Arial, serif;
  color: #fbff62;
  letter-spacing: 0px;
}
/* @media (min-width: 1199px) {
  .section1__hero_title22 {
    font-size: 32px;
    text-align: left;
  }
}
@media (min-width: 991px) {
  .section1__hero_title22 {
    font-size: 29px;
  }
}
@media (min-width: 767px) {
  .section1__hero_title22 {
    font-size: 25px;
  }
}
@media (min-width: 575px) {
  .section1__hero_title22 {
    font-size: 24px;
  }
}
@media (min-width: 479px) {
  .section1__hero_title22 {
    font-size: 22px;
  }
}
@media (min-width: 383px) {
  .section1__hero_title22 {
    font-size: 21px;
  }
} */

.section1__hero_title22.layout {
  position: relative;
  flex: 1 1 auto;
  margin: 30px 0px 0px;
}

.section1__big_title1 {
  display: flex;
  justify-content: left;
  font: 400 32px/1.19 "KozGoProBold", Helvetica, Arial, serif;
  color: white;
  text-align: right;
  letter-spacing: 0px;
}
/* @media (min-width: 1919px) {
  .section1__big_title1 {
    justify-content: flex-start;
    text-align: left;
  }
}
@media (min-width: 1199px) {
  .section1__big_title1 {
    font-size: 29px;
  }
}
@media (min-width: 991px) {
  .section1__big_title1 {
    font-size: 25px;
  }
}
@media (min-width: 767px) {
  .section1__big_title1 {
    font-size: 23px;
  }
}
@media (min-width: 575px) {
  .section1__big_title1 {
    font-size: 21px;
  }
}
@media (min-width: 479px) {
  .section1__big_title1 {
    font-size: 20px;
  }
}
@media (min-width: 383px) {
  .section1__big_title1 {
    font-size: 19px;
  }
} */


  .section1__big_title1.layout {
    position: relative;
    flex: 1 1 auto;
    margin: 8px 0 58px 32px;
}
/* @media (min-width: 1199px) {
  .section1__big_title1.layout {
    margin: 51px 12px 82px;
  }
}
@media (min-width: 991px) {
  .section1__big_title1.layout {
    margin: 51px 10px 82px;
  }
}
@media (min-width: 767px) {
  .section1__big_title1.layout {
    margin: 51px 9px 82px;
  }
}
@media (min-width: 575px) {
  .section1__big_title1.layout {
    margin: 51px 8px 82px;
  }
}
@media (min-width: 479px) {
  .section1__big_title1.layout {
    margin: 51px 7px 82px;
  }
} */

.section1__flex.layout4 {
  position: relative;
  margin: 0px 0px 0px;
}

.section1__block4 {
  display: flex;
  flex-direction: column;
  background-color: whitesmoke;
}

.section1__block4.layout {
  position: relative;
  overflow: hidden;
}

.section1__flex4.layout {
  position: relative;
  flex-grow: 1;
  width: 100%;
  padding: 0 5% 0 5%;
  margin: 0;
  /* justify-content: center; */
}
/* @media (min-width: 991px) {
  .section1__flex4.layout {
    width: 73.47%;
  }
}
@media (min-width: 767px) {
  .section1__flex4.layout {
    width: 78.69%;
  }
}
@media (min-width: 575px) {
  .section1__flex4.layout {
    width: 83.12%;
  }
}
@media (min-width: 479px) {
  .section1__flex4.layout {
    width: 86.78%;
  }
}
@media (min-width: 383px) {
  .section1__flex4.layout {
    width: 89.75%;
  }
} */

.section1__section1__flex4 > .item1 {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 1 521px;
}
/* @media (min-width: 1199px) {
  .section1__section1__flex4 > .item1 {
    flex: 0 0 50%;
  }
}

@media (min-width: 1199px) {
  .section1__hero_title3_box {
    align-items: flex-start;
    justify-content: flex-start;
  }
}
@media (min-width: 991px) {
  .section1__hero_title3_box {
    align-items: flex-start;
    justify-content: flex-start;
  }
}
@media (min-width: 767px) {
  .section1__hero_title3_box {
    align-items: flex-start;
    justify-content: flex-start;
  }
}
@media (min-width: 575px) {
  .section1__hero_title3_box {
    align-items: flex-start;
    justify-content: flex-start;
  }
}
@media (min-width: 479px) {
  .section1__hero_title3_box {
    align-items: flex-start;
    justify-content: flex-start;
  }
}
@media (min-width: 383px) {
  .section1__hero_title3_box {
    align-items: flex-start;
    justify-content: flex-start;
  }
} */

.section1__hero_title3_box.layout {
  position: relative;
  flex-grow: 1;
  margin: 12% 0px 20px;
}

.section1__hero_title3 {
  width: 100%;
  font: 400 36px/1.2 "KozGoProBold", Helvetica, Arial, serif;
  color: black;
  letter-spacing: 0px;
}
/* @media (min-width: 1199px) {
  .section1__hero_title3 {
    font-size: 32px;
    text-align: left;
  }
}
@media (min-width: 991px) {
  .section1__hero_title3 {
    font-size: 29px;
  }
}
@media (min-width: 767px) {
  .section1__hero_title3 {
    font-size: 25px;
  }
}
@media (min-width: 575px) {
  .section1__hero_title3 {
    font-size: 24px;
  }
}
@media (min-width: 479px) {
  .section1__hero_title3 {
    font-size: 22px;
  }
}
@media (min-width: 383px) {
  .section1__hero_title3 {
    font-size: 21px;
  }
} */

.section1__hero_title3_span0 {
  font: 1em/1.2 "KozGoProBold", Helvetica, Arial, serif;
  color: #1b1b1bff;
  /* font-style: normal; */
  letter-spacing: 0px;
}

.section1__hero_title3_span1 {
  font: 700 1em/1.2 "KozGoProBold", Helvetica, Arial, serif;
  color: #6319b8ff;
  /* font-style: normal; */
  letter-spacing: 0px;
}

.section1__hero_title3_span2 {
  font: 1em/1.2 "KozGoProBold", Helvetica, Arial, serif;
  color: #1b1b1bff;
  /* font-style: normal; */
  letter-spacing: 0px;
}

.section1__section1__flex4 > .item2 {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 1 300px;
}
/* @media (min-width: 1199px) {
  .section1__section1__flex4 > .item2 {
    flex: 0 0 50%;
  }
} */

.section1__image15.layout1 {
  position: relative;
}
/* @media (min-width: 991px) {
  .section1__image15.layout1 {
    width: calc(100% - 6px);
    margin: 0px 0px 0px 6px;
  }
} */

.section1__block5 {
  display: flex;
  justify-content: center;
  background-color: #6319b8;
}
/* @media (min-width: 1199px) {
  .section1__block5 {
    flex-wrap: wrap;
    align-content: flex-start;
    -moz-column-gap: 0px;
    column-gap: 0px;
    row-gap: 16px;
  }
} */

.section1__block5.layout {
  padding: 2% 5% 0% 5%;
  /* margin-top: 2%; */
  position: relative;
  overflow: hidden;
  /* margin: 0px 0px 0px; */
}

.section1__section1__block5 > .item {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 1 550px;
}
/* @media (min-width: 1199px) {
  .section1__section1__block5 > .item {
    flex: 0 0 50%;
  }
} */

.section1__image15.layout2 {
  position: relative;
  width: calc(100% - 185px);
  margin: 42px 54px 0px 131px;
}
/* @media (min-width: 1199px) {
  .section1__image15.layout2 {
    width: calc(100% - 197px);
    margin: 126px 1px 0px 196px;
  }
}
@media (min-width: 991px) {
  .section1__image15.layout2 {
    width: calc(100% - 137px);
    margin: 70px 0px 0px 137px;
  }
}
@media (min-width: 767px) {
  .section1__image15.layout2 {
    width: calc(100% - 87px);
    margin: 70px 0px 0px 87px;
  }
}
@media (min-width: 575px) {
  .section1__image15.layout2 {
    width: calc(100% - 62px);
    margin: 70px 0px 0px 62px;
  }
}
@media (min-width: 479px) {
  .section1__image15.layout2 {
    width: calc(100% - 37px);
    margin: 70px 0px 0px 37px;
  }
}
@media (min-width: 383px) {
  .section1__image15.layout2 {
    width: calc(100% - 20px);
    margin: 70px 0px 0px 20px;
  }
} */

.section1__section1__block5 > .item1 {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 1 730px;
}
/* @media (min-width: 1199px) {
  .section1__section1__block5 > .item1 {
    flex: 0 0 50%;
  }
} */

.section1__flex11 {
  display: flex;
  flex-direction: column;
}

.section1__flex11.layout {
  position: relative;
  flex-grow: 1;
  margin: 10% 0px 0px 19px;
}
/* @media (min-width: 1199px) {
  .section1__flex11.layout {
    margin: 190px 68px 0px 163px;
  }
}
@media (min-width: 991px) {
  .section1__flex11.layout {
    margin: 190px 54px 0px 0px;
  }
}
@media (min-width: 767px) {
  .section1__flex11.layout {
    margin: 190px 43px 0px 0px;
  }
}
@media (min-width: 575px) {
  .section1__flex11.layout {
    margin: 190px 37px 0px 0px;
  }
}
@media (min-width: 479px) {
  .section1__flex11.layout {
    margin: 190px 31px 0px 0px;
  }
}
@media (min-width: 383px) {
  .section1__flex11.layout {
    margin: 190px 28px 0px 0px;
  }
} */

.section1__hero_title4 {
  font: 700 48px/1.2 "KozGoProBold", Helvetica, Arial, serif;
  color: white;
  letter-spacing: 0px;
}
/* @media (min-width: 1199px) {
  .section1__hero_title4 {
    font-size: 43px;
    text-align: left;
  }
}
@media (min-width: 991px) {
  .section1__hero_title4 {
    font-size: 37px;
  }
}
@media (min-width: 767px) {
  .section1__hero_title4 {
    font-size: 32px;
  }
}
@media (min-width: 575px) {
  .section1__hero_title4 {
    font-size: 30px;
  }
}
@media (min-width: 479px) {
  .section1__hero_title4 {
    font-size: 27px;
  }
}
@media (min-width: 383px) {
  .section1__hero_title4 {
    font-size: 25px;
  }
} */

.section1__hero_title4.layout {
  position: relative;
}

/* @media (min-width: 1199px) {
  .section1__big_title11_box {
    align-items: flex-start;
    justify-content: flex-start;
  }
}
@media (min-width: 991px) {
  .section1__big_title11_box {
    align-items: flex-start;
    justify-content: flex-start;
  }
}
@media (min-width: 767px) {
  .section1__big_title11_box {
    align-items: flex-start;
    justify-content: flex-start;
  }
}
@media (min-width: 575px) {
  .section1__big_title11_box {
    align-items: flex-start;
    justify-content: flex-start;
  }
}
@media (min-width: 479px) {
  .section1__big_title11_box {
    align-items: flex-start;
    justify-content: flex-start;
  }
}
@media (min-width: 383px) {
  .section1__big_title11_box {
    align-items: flex-start;
    justify-content: flex-start;
  }
} */

.section1__big_title11_box.layout {
  position: relative;
  margin: 40px 0px 0px 0px;
}
/* @media (min-width: 1199px) {
  .section1__big_title11_box.layout {
    margin: 40px 0px 0px 14px;
  }
}
@media (min-width: 991px) {
  .section1__big_title11_box.layout {
    margin: 40px 0px 0px 12px;
  }
}
@media (min-width: 767px) {
  .section1__big_title11_box.layout {
    margin: 40px 0px 0px 10px;
  }
}
@media (min-width: 575px) {
  .section1__big_title11_box.layout {
    margin: 40px 0px 0px 9px;
  }
}
@media (min-width: 479px) {
  .section1__big_title11_box.layout {
    margin: 40px 0px 0px 8px;
  }
} */

.section1__big_title11 {
  font: 400 32px/1.2 "KozGoProBold", Helvetica, Arial, serif;
  color: black;
  letter-spacing: 0px;
  margin-bottom: 12px;
}
/* @media (min-width: 1199px) {
  .section1__big_title11 {
    font-size: 29px;
    text-align: left;
  }
}
@media (min-width: 991px) {
  .section1__big_title11 {
    font-size: 25px;
  }
}
@media (min-width: 767px) {
  .section1__big_title11 {
    font-size: 23px;
  }
}
@media (min-width: 575px) {
  .section1__big_title11 {
    font-size: 21px;
  }
}
@media (min-width: 479px) {
  .section1__big_title11 {
    font-size: 20px;
  }
}
@media (min-width: 383px) {
  .section1__big_title11 {
    font-size: 19px;
  }
} */

.section1__big_title11_span0 {
  font: 1em/1.2 "KozGoProBold", Helvetica, Arial, serif;
  color: #ffffffff;
  /* font-style: normal; */
  letter-spacing: 0px;
}

.section1__big_title11_span1 {
  font: 700 1em/1.2 "KozGoProBold", Helvetica, Arial, serif;
  color: #fbff62ff;
  /* font-style: normal; */
  letter-spacing: 0px;
}

.section1__big_title11_span2 {
  font: 1em/1.2 "KozGoProBold", Helvetica, Arial, serif;
  color: #ffffffff;
  /* font-style: normal; */
  letter-spacing: 0px;
}

.section1__block6 {
  display: flex;
  justify-content: center;
  background-color: whitesmoke;
}
/* @media (min-width: 1199px) {
  .section1__block6 {
    flex-wrap: wrap;
    align-content: flex-start;
    -moz-column-gap: 0px;
    column-gap: 0px;
    row-gap: 16px;
  }
} */

.section1__block6.layout {
  padding: 0 5%;
  position: relative;
  overflow: hidden;
}
/* @media (min-width: 991px) {
  .section1__block6.layout {
    margin: 0px;
  }
} */

.section1__section1__block6 > .item {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 1 540px;
}
/* @media (min-width: 1199px) {
  .section1__section1__block6 > .item {
    flex: 0 0 50%;
  }
} */

.section1__hero_title3_box.layout1 {
  position: relative;
  flex-grow: 1;
  margin: 12% 0px 34px 0;
}
/* @media (min-width: 1199px) {
  .section1__hero_title3_box.layout1 {
    margin: 92px 0px 34px 159px;
  }
}
@media (min-width: 991px) {
  .section1__hero_title3_box.layout1 {
    margin: 92px 0px 55px 113px;
  }
}
@media (min-width: 767px) {
  .section1__hero_title3_box.layout1 {
    margin: 92px 0px 55px 74px;
  }
}
@media (min-width: 575px) {
  .section1__hero_title3_box.layout1 {
    margin: 92px 0px 55px 55px;
  }
}
@media (min-width: 479px) {
  .section1__hero_title3_box.layout1 {
    margin: 92px 0px 55px 35px;
  }
}
@media (min-width: 383px) {
  .section1__hero_title3_box.layout1 {
    margin: 92px 0px 55px 22px;
  }
} */

.section1__section1__block6 > .item1 {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 1 539px;
}
/* @media (min-width: 1199px) {
  .section1__section1__block6 > .item1 {
    flex: 0 0 50%;
  }
} */



.section1__image16 {
  position: relative;
 /* width: auto; */
 margin: 0;
}
/* @media (min-width: 1199px) {
  .section1__image16.layout {
    width: calc(100% - 8px);
    margin: 47px 0px 0px 8px;
  }
}
@media (min-width: 991px) {
  .section1__image16.layout {
    width: calc(100% - 119px);
    margin: 11px 119px 0px 0px;
  }
}
@media (min-width: 767px) {
  .section1__image16.layout {
    width: calc(100% - 84px);
    margin: 11px 84px 0px 0px;
  }
}
@media (min-width: 575px) {
  .section1__image16.layout {
    width: calc(100% - 60px);
    margin: 11px 60px 0px 0px;
  }
}
@media (min-width: 479px) {
  .section1__image16.layout {
    width: calc(100% - 36px);
    margin: 11px 36px 0px 0px;
  }
}
@media (min-width: 383px) {
  .section1__image16.layout {
    width: calc(100% - 20px);
    margin: 11px 20px 0px 0px;
  }
} */

.section1__flex4.layout2 {
  position: relative;
  margin: 20.39px 36px 18px 50px;
}
/* @media (min-width: 1199px) {
  .section1__flex4.layout2 {
    margin: 49.39px 35px 0px;
  }
}
@media (min-width: 991px) {
  .section1__flex4.layout2 {
    margin: 0px 29px;
  }
}
@media (min-width: 767px) {
  .section1__flex4.layout2 {
    margin: 0px 24px;
  }
}
@media (min-width: 575px) {
  .section1__flex4.layout2 {
    margin: 0px 21px;
  }
}
@media (min-width: 479px) {
  .section1__flex4.layout2 {
    margin: 0px 19px;
  }
}
@media (min-width: 383px) {
  .section1__flex4.layout2 {
    margin: 0px 17px;
  }
} */

.section1__section1__flex4 > .item3 {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 1 106px;
}
/* @media (min-width: 1199px) {
  .section1__section1__flex4 > .item3 {
    flex: 0 0 100%;
  }
} */

.section1__image17 {
  width: 100%;
  height: auto;
  vertical-align: top;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center top;
  object-position: center top;
}

.section1__image17.layout {
  position: relative;
  width: 106px;
  min-width: 106px;
}

.section1__section1__flex4 > .spacer {
  flex: 0 1 72px;
}
/* @media (min-width: 1199px) {
  .section1__section1__flex4 > .spacer {
    display: none;
  }
} */

.section1__section1__flex4 > .item4 {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 1 1019px;
}
/* @media (min-width: 1199px) {
  .section1__section1__flex4 > .item4 {
    flex: 0 0 100%;
  }
} */

.section1__flex4.layout1 {
  position: relative;
  flex-grow: 1;
  margin: 12px 0px 0px;
}

.section1__section1__flex4 > .item5 {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 0 auto;
  min-width: 179px;
}
/* @media (min-width: 1199px) {
  .section1__section1__flex4 > .item5 {
    flex: 0 0 100%;
    min-width: unset;
  }
} */

.section1__subtitle {
  font: 400 20px/1.2 "KozGoProBold", Helvetica, Arial, serif;
  color: #7c7c7c;
  letter-spacing: 0px;
}
/* @media (min-width: 1199px) {
  .section1__subtitle {
    font-size: 19px;
    text-align: left;
  }
}
@media (min-width: 991px) {
  .section1__subtitle {
    font-size: 18px;
  }
}
@media (min-width: 767px) {
  .section1__subtitle {
    font-size: 17px;
  }
}
@media (min-width: 479px) {
  .section1__subtitle {
    font-size: 16px;
  }
} */

.section1__subtitle.layout {
  position: relative;
  flex-grow: 1;
}

.section1__section1__flex4 > .spacer1 {
  flex: 0 1 62px;
}
/* @media (min-width: 1199px) {
  .section1__section1__flex4 > .spacer1 {
    display: none;
  }
} */

.section1__section1__flex4 > .item6 {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 0 auto;
  min-width: 778px;
}
/* @media (min-width: 1199px) {
  .section1__section1__flex4 > .item6 {
    flex: 0 0 100%;
    min-width: unset;
  }
} */

.section1__subtitle1 {
  font: 400 20px/1.2 "KozGoProBold", Helvetica, Arial, serif;
  color: #818181;
  letter-spacing: 0px;
}
/* @media (min-width: 1199px) {
  .section1__subtitle1 {
    font-size: 19px;
    text-align: left;
  }
}
@media (min-width: 991px) {
  .section1__subtitle1 {
    font-size: 18px;
  }
}
@media (min-width: 767px) {
  .section1__subtitle1 {
    font-size: 17px;
  }
}
@media (min-width: 479px) {
  .section1__subtitle1 {
    font-size: 16px;
  }
} */

.section1__subtitle1.layout {
  position: relative;
  flex-grow: 1;
}
a {
  color:#0a0a0a; 
  text-decoration:none; 
  cursor:pointer;  
}
a:hover {
  color:#0a0a0a; 
  text-decoration:none; 
  cursor:pointer;  
}

.cstm-M{
margin-left: 20px;
margin-right: 20px;
}

.section1__flex44 {
  display: flex;
  padding-left: 22px;
  justify-content: left;
  padding-top: 22px;
  padding-bottom: 22px;
}

@media (max-width: 999px){
  .cstm-M{
    margin-left: 0px;
    margin-right: 30px;
    }
}

@media (max-width: 860px){
  .section1__image1 {
    width: 523px;
    height: auto;
    vertical-align: bottom;
    object-position: center top;
  }
  .section1__image.layout {
    position: relative;
    flex: 1 1 auto;
    width: 31.61%;
    margin: 0px 0% 0px 7%;
    transform: scale(1.3);
}
.section1__flex21.layout {
  position: relative;
  flex-grow: 1;
  margin: 55px 0px -45px 23px;
}
.section1__big_title1.layout {
  position: relative;
  flex: 1 1 auto;
  margin: 0 0 60px 0;
}
.cstm-MM{
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.section1__flex.layout {
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  margin: 67px 0% 0px 0%;
}
.section1__hero_title1.layout {
  position: relative;
  margin: 38px 0px 12px;
}

}

.cstm-MM{
  margin-left: 20px;
  margin-right: 20px;
}

@media(max-width:780px){

  .section1__flex.layout {
    position: relative;
    flex: 1 1 auto;
    width: 100%;
    margin: 24px 0% 0px 0%;
}

.section1__image16 {
  width: 100% !important;
  /* height: 100%; */
  position: relative;
  /* margin-top: 11% !important; */
}

.section1__image15a {
  position: relative;
  width: auto;
  width: 100% !important;
  height: 100% !important;
  /* margin-top: 10% !important; */
}

  .section1__image15b{
    width:100% !important;
  }

  .section1__image15c {
    /* margin-top: 4%; */
    width: 100%;
    /* height: 100%; */
}

  .section1__hero_title3_box.layout {
    position: relative;
    flex-grow: 1;
    margin: 5% 0px 10px;
}
.section1__hero_title22.layout {
  position: relative;
  flex: 1 1 auto;
  margin: 22px 0px 0px;
}
.section1__hero_title21.layout {
  position: relative;
  flex: 1 1 auto;
  margin: 22px 0px 0px;
}
.section1__image1 {
  width: 474px;
  /* height: 429px !important; */
  /* vertical-align: bottom; */
  /* object-position: center top; */
  margin-top: 0 !important;
}



}


@media (max-width: 750px){
.section1__image1 {
    width: 443px;
    height: auto;
    vertical-align: bottom;
    object-position: center top;
}
.cstm-wid{
  width: 157px !important;
}
.section1__flex.layout {
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  margin: 48px 0% 0px 0%;
}
.section1__hero_title1.layout {
  position: relative;
  margin: 20px 0px 12px;
}
}

@media (min-width: 1000px) and (max-width: 1024px)  {


  .section1__flex.layout {
    position: relative;
    flex: 1 1 auto;
    width: 100%;
    margin: 24px 0% 0px 0%;
}

.section1__image16 {
  width: 50% !important;
  /* height: 100%; */
  position: relative;
  margin-top: 3% !important;
}

.section1__image15a {
  position: relative;
  /* width: auto; */
  width: 30% !important;
  /* height: 100% !important; */
  margin-top: 3% !important;
}

.section1__image15b {
  width: 50% !important;
  margin-top: 3% !important;
}

.section1__image15c {
  /* margin-top: 4%; */
  width: 30%;
  margin-top: 3% !important;
  /* height: 100%; */
}

  .section1__hero_title3_box.layout {
    position: relative;
    flex-grow: 1;
    margin: 5% 0px 10px;
}
.section1__hero_title22.layout {
  position: relative;
  flex: 1 1 auto;
  margin: 22px 0px 0px;
}
.section1__hero_title21.layout {
  position: relative;
  flex: 1 1 auto;
  margin: 22px 0px 0px;
}
.section1__image1 {
  width: 43%;
  /* vertical-align: bottom; */
  /* object-position: center top; */
  /* margin-top: 12% !important; */
  margin-top: 3%;
}

.section1__flex1.layout {
  position: relative;
  flex-grow: 1;
  /* width: 100%; */
  margin: 13px 0px 0 60px;
  /* padding-right: 5%; */
  /* justify-content: space-evenly; */
}



}

@media (min-width:300px)and (max-width:750px){
  .section1__image15a {
    position: relative;
    /* width: auto; */
    width: 50% !important;
    /* height: 100% !important; */
    /* margin-top: 12% !important; */
    /* height: 177px !important; */
}
.cstm-M{
  margin-left: 0px;
  margin-right: 0px;
  }


.section1__image15b {
  width: 54% !important;
  /* margin-top: 14% !important; */
  /* height: 181px; */
}

.section1__image15c {
  /* margin-top: 4%; */
  width: 50%;
  /* margin-top: 13% !important; */
  /* height: 203px; */
}

.section1__image15c {
  /* margin-top: 4%; */
  width: 50%;
  /* margin-top: 13% !important; */
  /* height: 203px; */
}

  .section1__hero_title3_box.layout {
    position: relative;
    flex-grow: 1;
    margin: 5% 0px 10px;
}
.section1__hero_title22.layout {
  position: relative;
  flex: 1 1 auto;
  margin: 2px 0px 6px;
}
.section1__hero_title21.layout {
  position: relative;
  flex: 1 1 auto;
  margin: 1px 0px 0px;
}
.section1__image1 {
  width: 50% !important;
 
}
.section1__flex1.layout {
  position: relative;
  flex-grow: 1;
  /* width: 100%; */
  margin: 8px 0px 0 16px;
  /* padding-right: 5%; */
  /* justify-content: space-evenly; */
}

.section1__image.layout {
  position: relative;
  flex: 1 1 auto;
  width: 75% !important;
  margin: 0px 0% 0px 0%;
  transform: scale(1);
}


.section1__flex.layout {
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  margin: 10px 0px 0px 0px;
}
.section1__hero_title {
  display: flex;
  font: 700 20px/1.2 "KozGoProBold", Helvetica, Arial, serif !important;
  color: white;
  letter-spacing: 0px;
}
.section1__hero_title1.layout {
  position: relative;
  margin: 10px 0px 0px;
}

.section1__hero_title1 {
  font: 700 24px/1.2 "KozGoProBold", Helvetica, Arial, serif;
  color: white;
  letter-spacing: 0px;
}
.section1__block1.layout {
  padding: 0% 5% 0% 5%;
  /* padding-bottom: 20px; */
  position: relative;
  overflow: hidden;
  flex-grow: 1;
}
.section1__hero_title11.layout {
  position: relative;
  width: 100%;
  margin: 22px 0px 5px -7px;
}
.section1__hero_title11 {
  display: flex;
  justify-content: center;
  font: 700 22px/1.2 "KozGoProBold", Helvetica, Arial, serif;
  color: #1b1b1b;
  text-align: center;
  letter-spacing: 0px;
}
.section1__medium_title.layout {
  position: relative;
  width: 100%;
  margin: 5px 0px 5px 0px;
}
.section1__medium_title {
  display: flex;
  justify-content: center;
  font: 400 14px/1.2 "KozGoProBold", Helvetica, Arial, serif;
  color: #222222;
  text-align: center;
  letter-spacing: 0px;
}
.section1__flex2.layout {
  margin-top: 12px;
  position: relative;
  height: min-content;
  width: 100%;
  justify-content: center;
  /* margin: 46px 46.7% 31px 34.49%; */
}

.section1__block2.layout {
  position: relative;
  height: 76px;
  width: 141px;
  min-width: 0px;
}
.section1__image6.layout {
  margin-left: 6%;
  width: 160px !important;
  margin-right: 0px !important;
  /* transform: scaleX(1); */

}
.section1__block3.layout {
  padding: 0% 5% 0% 5%;
  position: relative;
  overflow: hidden;
}
.section1__flex21.layout {
  margin-left: 0%;
  margin-top: 18%;
  position: relative;
  flex-grow: 1;
  /* margin: 3%; */
  margin-right: 4%;
  margin-bottom: 0%;
}

.section1__hero_title2 {
  font: 700 22px/1.19 "KozGoProBold", Helvetica, Arial, serif;
  color: white;
  letter-spacing: 0px;
}
.section1__big_title {
  
  font: 400 16px/1.2 "KozGoProBold", Helvetica, Arial, serif;
  color: white;
  letter-spacing: 0px;
}

.section1__hero_title21 {
  font: 700 20px/1.2 "KozGoProBold", Helvetica, Arial, serif;
  color: #fbff62;
  letter-spacing: 0px;
}

.section1__hero_title22 {
  font: 700 20px/1.19 "KozGoProBold", Helvetica, Arial, serif;
  color: #fbff62;
  letter-spacing: 0px;
}
.section1__big_title1 {
  display: flex;
  justify-content: left;
  font: 400 16px/1.19 "KozGoProBold", Helvetica, Arial, serif;
  color: white;
  text-align: left;
  letter-spacing: 0px;
}
.section1__big_title1.layout {
  position: relative;
  flex: 1 1 auto;
  margin: 0 0 13px 13px;
}

.section1__big_title.layout {
  position: relative;
  flex: 1 1 auto;
  margin: 6px 16px 0px 10px;
}

.section1__hero_title3 {
  width: 100%;
  font: 400 23px/1.2 "KozGoProBold", Helvetica, Arial, serif;
  color: black;
  letter-spacing: 0px;
  margin: 1% 0% 1% 0%;
}

.section1__image16 {
  width: 50% !important;
  /* height: 200px; */
  position: relative;
  /* margin-top: 24% !important; */
}

.section1__flex11.layout {
  position: relative;
  flex-grow: 1;
  margin: 6% 0% 0% 2%;
}

.section1__hero_title4 {
  font: 700 24px/1.2 "KozGoProBold", Helvetica, Arial, serif;
  color: white;
  letter-spacing: 0px;
}

.section1__big_title11_box.layout {
  position: relative;
  margin: 10px 0px 0px 0px;
}
.section1__big_title11 {
  font: 400 16px/1.2 "KozGoProBold", Helvetica, Arial, serif;
  color: black;
  letter-spacing: 0px;
  margin-bottom: 12px;
}
.section1__block5.layout {
  padding: 2% 5% 0% 5%;
  /* margin-top: 2%; */
  position: relative;
  overflow: hidden;
  /* margin: 0px 0px 0px; */
}

.section1__flex44 {
  padding: 2%;
  display: flex;
  /* padding-left: 10px; */
  justify-content: left;
  /* padding-top: 10px;
  padding-bottom: 10px; */
}
.section1__image17.layout {
  position: relative;
  width: 70px;
  min-width: 0px;
}
.section1__flex4.layout1 {
  position: relative;
  flex-grow: 1;
  margin: 5px 0px 0px 0px;
}
.section1__subtitle1.layout {
  margin-left: 0px !important;
}
.section1__subtitle1 {
  font: 400 15px/1.2 "KozGoProBold", Helvetica, Arial, serif;
  color: #818181;
  letter-spacing: 0px;
}
.section1__subtitle {
  font: 400 15px/1.2 "KozGoProBold", Helvetica, Arial, serif;
  color: #7c7c7c;
  letter-spacing: 0px;
}
.section1__hero_title3_box.layout1 {
  flex-grow: 1;
  margin: 12% 0 10px;
  position: relative;
}

}